import { withWidth } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense, useContext, useEffect, useState } from 'react'
import KMProgressBar from 'components/KMProgressBar/KMProgressBar'
import PropTypes from 'prop-types'
import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
// creates a beautiful scrollbar
// import PerfectScrollbar from 'perfect-scrollbar'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'
import withStyles from '@material-ui/core/styles/withStyles'
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import dashboardRoutes from 'routes/dashboard.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'
import image from 'assets/img/sidebar-6.jpg'
import KMCan from 'components/KMCan/KMCan'
import { withUser } from 'stores/KMUserProvider'
import { withLangStore } from "stores/KLangProvider.js";
import { KMCanChecker } from 'components/KMCan/KMCan'
import { withClient } from 'stores/KMClientProvider'
import { KMInfraContext } from 'stores/KMInfraProvider'

const switchRoutes = (user) => (
  <Suspense fallback={<div></div>}>
    <Routes>
      {dashboardRoutes.map((prop, key) => {
        const canView = KMCanChecker({
          user: user.user,
          perform: prop.rbac
        })
        console.log(prop.path)

        if (prop.redirect) {

          if (user.userStateCode === 'KM_SUPERVISOR') {
            // return  <Navigate from={prop.path} to={prop.to} key={key} />
            return window.location.href = prop.to
          }
          else {
            // return window.location.href="/careFolders"
            // return <Navigate from={prop.path} to={"/careFolders"} key={key} />
          }
        }
        if (!!canView === false) return null
        console.log(prop.path)
        return <Route path={prop.path} Component={prop.component} key={key} />
      })}
    </Routes>
  </Suspense>
)

const App = (props) => {
  let location = useLocation()
  const [mobileOpen, setMobileOpen] = useState(false)
  const [langStoreReady, setLangStoreReady] = useState(false)
  const [isContainerFullWidth, setIsContainerFullWidth] = useState(false)
  const { infraStore, infraStoreRightPermissionDenied } = useContext(KMInfraContext);

  const { classes, ...rest } = props
  let textNoActifUser
  let dashboardRoutesForSidebar = dashboardRoutes.filter(route => route.sidebarName !== '')
  dashboardRoutesForSidebar.push(infraStore.privateRoutes.filter(route => !!route.routeFlagDisplayInReactSideBarMenu))
  debugger
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };
  debugger
  useEffect(() => {
    let { width } = props
    setIsContainerFullWidth(location.pathname.match(/\/report\/[0-9]+/) && width !== 'sm' && width !== 'xs')
  }, [props.width])

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  useEffect(() => {
    window.addEventListener('resize', resizeFunction())
    props.initLangStore().then(data => {
      setLangStoreReady(true)
    })
    return () => {
      window.removeEventListener('resize', resizeFunction())
    }
  }, [])

  return (
    <KMCan
      perform="layout/Dashboard/Dashboard:visit"
      yes={() => (
        <div className={classes.wrapper}>
          <Sidebar
            isContainerFullWidth={() => isContainerFullWidth()}
            routes={dashboardRoutesForSidebar}
            logoText={''}
            logo={props.client.kmClientLogo}
            image={image}
            handleDrawerToggle={() => handleDrawerToggle()}
            open={mobileOpen}
            color="blue"
            {...rest}
          />
          <div className={
            clsx({
              [classes.mainPanel]: true,
              [classes.mainPanelFullWidth]: () => isContainerFullWidth(),
            })
          } >              <KMProgressBar />
            <Header
              routes={dashboardRoutes}
              handleDrawerToggle={() => handleDrawerToggle()}
              {...rest}
            />
            {isContainerFullWidth === false ? (
              <div className={classes.content}>
                <div className={classes.container}>{switchRoutes(props)}</div>
              </div>
            ) : (
              <div style={{ flexGrow: '1 !important', width: 'auto' }}>{switchRoutes(props)}</div>
            )}
            <Footer />

          </div>
        </div>
      )}
      no={() =>
        <div className={classes.wrapper}>
          <Sidebar
            isContainerFullWidth={() => isContainerFullWidth()}

            routes={[]}
            logoText={''}
            logo={props.client.kmClientLogo}
            image={image}
            handleDrawerToggle={() => handleDrawerToggle()}
            open={mobileOpen}
            color="blue"
            {...rest}
          />
          <div className={classes.mainPanel} >
            <div className={classes.content}>
              <div className={classes.container}>
                <div>
                  {textNoActifUser}
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>

      }
    />
  )
}

App.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withWidth()(withStyles(dashboardStyle)(withUser(withLangStore(withClient(App)))))
