module.exports = {
  'loginForm': {

    'title1': '',
    'title2': 'Connectez-vous',
    'fillAllBlanks': 'Remplissez les champs suivants',
    'subscribeNow': 'inscrivez-vous ici',
    'connectNow': 'connectez-vous ici',
    'titleSocials': 'Ou connecter vous directement via',
    'socialsCgu': 'En vous connectant via votre compte de réseaux sociaux, vous déclarez accepter les conditions générales d"utilisations',
    'loginAction': 'Se connecter',
    'registerAction': `Faire une demande d'inscription`,
    'logout': 'Déconnexion',
    'loginOr': 'Login or',
    'or': 'ou',
    'signUp': 'Créer un compte',
    'rememberMe': 'Se souvenir de moi',
    'forgotPassword': 'Mot de passe oublié ?',
    'error': {
      'password': 'Le mot de passe est obligatoire',
      'passwordStrength': 'Le mot de passe doit contenir au moins 5 caractères avec au moins une minuscule + une majuscule + un chiffre + un caractère spécial. Par exemple %!@#$&*-.+~{[|^]} ',
      'firstName': 'Le prénom est obligatoire',
      'lastName': 'Le nom est obligatoire',
      'etablissementId': 'Veuillez choisir un établissement',
      'establishmentId': 'Veuillez choisir un établissement',
      'email': `L'email est obligatoire`,
      'oauth': {
        'access_denied': 'Vous avez refusez la connexion, donc nous ne pouvons pas vous identifier, veuillez accepter afin de vous connecter',
        'not_found': `Nous n'avons pas trouvé de correspondance dans notre base de donnée. Veuillez soit contacter l'administrateur si le problème persiste ou utilisez le lien de mot de passe oublié.`,
        'notRegister': `Nous n'avons pas réussi à créer votre fiche. Veuillez soit contacter l'administrateur si le problème persiste ou réessayer un peu plus tard.`
      }
    },
    firstName: `Votre prénom ...`,
    lastName: `Votre nom ...`,
    email: `Email ...`,
    password: `Mot de passe ...`,
    etablissementId: 'Votre établissement ...',
    establishmentId: 'Votre établissement/ votre service ...'

  },

  'KMPopupForgotPassword': {

    userEmailLabel: {
      text: `Entrez votre email`,
      speech: [

      ],
      ariaLabel: `Entrez votre email`
    },
    expireTitle: {
      text: `Faire expirer le password de l'utilisateur?`,
      speech: [

      ],
      ariaLabel: `Faire expirer le password de l'utilisateur?`
    },
    expireSubTitle: {
      text: `Mot de passe oublié ?`,
      speech: [

      ],
      ariaLabel: `Mot de passe oublié ?`
    }
    ,
    expireDescription: {
      text: `Entrez votre email pour nous aider à vous authentifier.`,
      speech: [

      ],
      ariaLabel: `Entrez votre email pour nous aider à vous authentifier.`
    },
    closePopin: {
      text: `Fermer la pop-in`,
      speech: [

      ],
      ariaLabel: `Fermer la pop-in`
    },
    resendMessaegAction: {
      text: `Renvoyez moi un mon mot de passe`,
      speech: [

      ],
      ariaLabel: `Renvoyez moi un mon mot de passe`
    }
  },
  'HeaderLinks': {
    enableTextToSpeech: {
      text: `Lecture vocale`,
      speech: [
        { speech: `La fonctionnalité de lecture du texte est maintenant activée.`, queue: true },
        { speech: `Vous pouvez double cliquer, ou faire un click long sur les textes du site ou sur cette même îcone pour avoir une version vocalisée du texte.`, queue: true },
        { speech: `Pour un rendu de voix optimale, utilisez si possible le navigateur Chrome.`, queue: true }
      ],
      ariaLabel: `Activer le text to Speech`
    }
  },

  'KDAE_CareFolderView': {

    closePopinButton: {
      text: `Fermer`,
      speech: [
      ],
      ariaLabel: 'Fermer cette popin sans envoyer le formulaire'
    },
    sendFormButton: {
      text: `Envoyez votre demande`,
      speech: [
      ],
      ariaLabel: "Bouton pour enregistrer et envoyer votre demande"
    },

    userOptinMAH: {
      text: `Votre MAH vous informe toute l'année. En cochant cette case j'accepte de recevoir des offres de stage, des invitations à des conférences...`,
      speech: [
        { speech: `Votre MAH vous informe toute l'année. En cochant cette case j'accepte de recevoir des offres de stage, des invitations à des conférences...`, queue: true }
      ]
    },
    userProductPackage1: {
      ariaLabel: `Accessibilité des locaux. (Ex: ascenseur, salle d'épreuve au rez-de-chausée, salle isolée...)`,

      text: `<div><b>Accessibilité des locaux</b> (Ex: ascenseur, salle d'épreuve au rez-de-chausée, salle isolée...)</div>`,
      speech: [
        { speech: `Accessibilité des locaux. (Ex: ascenseur, salle d'épreuve au rez-de-chausée, salle isolée...)`, queue: true }
      ]
    },
    userProductPackage2: {
      ariaLabel: `Installation particulière de la salle. (Exemples: éclairage particulier, proximité des toilettes...)`,

      text: `<div><b>Installation particulière de la salle</b> (Exemples: éclairage particulier, proximité des toilettes...)</div>`,
      speech: [
        { speech: `Installation particulière de la salle. (Exemples: éclairage particulier, proximité des toilettes...)`, queue: true }
      ]
    },
    userProductPackage3: {
      ariaLabel: `Installation particulière de matériel. (Exemples: Loupe, ordinateur, logiciel...)`,

      text: `<div><b>Installation particulière de matériel</b> (Exemples: Loupe, ordinateur, logiciel...)</div>`,
      speech: [
        { speech: `Installation particulière de matériel. (Exemples: Loupe, ordinateur, logiciel...)`, queue: true }
      ]
    },
    userProductPackage4: {
      ariaLabel: `Assistance d'une tierce personne`,

      text: `<div><b>Assistance d'une tierce personne</b></div>`,
      speech: [
        { speech: `Assistance d'une tierce personne`, queue: true }
      ]
    },
    userProductPackage5: {
      ariaLabel: `Agrandissement des documents`,

      text: `<div><b>Agrandissement des documents</b></div>`,
      speech: [
        { speech: `Agrandissement des documents`, queue: true }
      ]
    },
    userProductPackage6: {
      ariaLabel: `Majoration d'un tiers temps supplémentaire pour les épreuves`,

      text: `<div><b>Majoration d'un tiers temps supplémentaire pour les épreuves</b></div>`,
      speech: [
        { speech: `Majoration d'un tiers temps supplémentaire pour les épreuves`, queue: true }
      ]
    },
    userProductPackage7: {
      ariaLabel: `Autres`,

      text: `<b>Autres</b>`,
      speech: [
        { speech: `Autres`, queue: true }
      ]
    },
    userProductPackage8: {
      ariaLabel: `Situation stage à l'étranger`,
      text: `<div><b>Situation stage à l'étranger</b></div>`,
      speech: [
        { speech: `Situation stage à l'étranger`, queue: true }
      ]
    },

    title: {
      text: `Formulaire de demande d'aménagement`,
      speech: [
        { speech: `C'est le formulaire qui vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`, queue: false }
      ],
      ariaDescribedby: `formulaire-permettant-d-initier-ou-renouveler-votre-demande-d'amenagement-pour-vos-cours-ou-examens`
    },
    explain: {
      text: `C'est le formulaire qui vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`,
      speech: [
        { speech: `C'est le formulaire qui vous permet d'initier ou de renouveler vos demandes d'aménagements pour vos cours et vos examens.`, queue: false }

      ],
      ariaLabelledby: `formulaire-d-inscription-et-de-demande-d-amenagements`
    },
    form: {
      careFolderValidationComment:{
        text: `Commentaire sur le dossier PDF`,
        speech: [],
        ariaLabel: 'Commentaire sur le dossier PDF',
        placeHolder: `Ce message sera visible à la fin du dossier PDF de l'étudiant`,
        textMessage: 'Le champ commentaire sur le dossier PDF est vide'
      },
      userFirstName: {
        text: `Prénom`,
        speech: [
        ],
        ariaLabel: 'Prénom',
        textMessage: 'Le champ prénom est vide'
      },
      userLastName: {
        text: `Nom`,
        speech: [
        ],
        ariaLabel: 'Nom',
        textMessage: 'Le champ nom est vide'
      },
      careFolderValidityTimeRangeFromDatetime: {
        text: `Valide du`,
        speech: [
        ],
        ariaLabel: 'Valide du'
      },
      careFolderValidityTimeRangeToDatetime: {
        text: `au`,
        speech: [
        ],
        ariaLabel: 'au'
      },
      userBirthDay: {
        text: `Date de naissance`,
        textMessage: 'Le champ date de naissance est vide ou n\'est pas valide',
        speech: [
        ],
        ariaLabel: 'Date de naissance'
      },
      establishmentId: {
        text: ``,
        textMessage: 'Ce champ est obligatoire',
        speech: [
        ],
        ariaLabel: ''
      },
      careFolderSeasonDatetime: {
        text: `Année du dossier`,
        speech: [
        ],
        ariaLabel: 'Année du dossier'
      },
      userGender: {
        text: `Sexe`,
        speech: [
        ],
        ariaLabel: 'Sexe'
      },
      userGenderMale: {
        text: `M.`,
        speech: [
        ],
        ariaLabel: ''
      },
      userGenderFemale: {
        text: `Mme`,
        speech: [
        ],
        ariaLabel: ''
      },
      userRenew: {
        text: `S'agit t'il d'une demande de renouvellement ?`,
        speech: [
        ],
        ariaLabel: 'Demande de renouvellement'
      },
      userRenewYes: {
        text: `Oui`,
        speech: [
        ],
        ariaLabel: ''
      },
      userRenewNo: {
        text: `Non`,
        speech: [
        ],
        ariaLabel: ''
      },
      userEmail: {
        text: `Email personnel/identifiant`,
        speech: [
        ],
        ariaLabel: 'Courriel',
        placeHolder: 'Email personnel',
        textMessage: `L'email est obligatoire`
      },
      userEmailCatho: {
        text: `Email université`,
        speech: [
        ],
        ariaLabel: 'Email de la catho',
        placeHolder: 'email finissant par @univ-catholille.fr'
      },
      userMobilePhone: {
        text: `Numéro de mobile`,
        speech: [
        ],
        ariaLabel: 'Numéro de téléphone',
        textMessage: 'Le numéro de téléphone ne semble pas valide'
      },
      userSchoolYear: {
        text: `Année`,
        speech: [
        ],
        ariaLabel: 'Année dans la scolarité. Licence 1, Licence 2, Licence 3, Master 1, Master 2, Echange, Autre à précisez'
      },
      userTypeHandicap: {
        text: `Temporalité`,
        speech: [
        ],
        ariaLabel: 'Temporalité : pour l\'ensemble du cursus, pour l\'année, ponctuelle'
      },
      userSchoolYearOther: {
        text: `Autre. Précisez :`,
        speech: [
        ],
        ariaLabel: 'Autre. Précisez :',
        textMessage: 'Ce champ est obligatoire'
      },
      userTrainingLabel: {
        text: `Intitulé de la formation`,
        speech: [
        ],
        ariaLabel: 'Intitulé de la formation',
        textMessage: 'Le champ intitulé de la formation est vide'
      },
      userProductPackage7Detail: {
        text: `Précisez :`,
        speech: [
        ],
        ariaLabel: 'Précisez :',
        textMessage: 'Ce champ est obligatoire'
      }
    }


  },


  'home': {
    login: {
      text: `Bonjour, Identifiez vous`,
      speech: [
      ],
      ariaDescribedby: ``,
      ariaLabelledby: ``

    },
    title: {
      text: `
Formulaire de demande d'aménagement
pour les étudiants
en situation de handicap
      `,
      speech: [
        { speech: `Formulaire de demande d'aménagement pour les étudiants en situation de handicap`, queue: false }
      ]
    },
    mahLogo: {
      text: `Logo de la Mission Accueil Handicap de l'univérsité Catholique de Lille`,
      speech: [
      ]
    },
    intro: {
      text: `
L'université Catholique de Lille permet l'accès de chacun aux études supérieures qu'il souhaite mener.
Si vous êtes en situation de handicap, vous pouvez demander des aménagements de vos cours et / ou de vos examens .
Voici comment cela fonctionne :`,
      speech: [
        { speech: `L'université Catholique de Lille permet l'accès de chacun aux études supérieures qu'il souhaite mener. Si vous êtes en situation de handicap, vous pouvez demander des aménagements de vos cours et de vos examens. Voici comment cela fonctionne`, queue: false }
      ]
    },

    step1TabButton: {
      text: `Etape 1`,
      speech: [

      ]
    },
    step1Title: {
      text: `Inscrivez-vous`,
      speech: [

      ]
    },
    step1Alt: {
      text: `Etape 1. Inscrivez-vous en suivant le bouton s'inscrire`,
      speech: [

      ]
    },
    step1RenewTitle: {
      text: `Connectez-vous`,
      speech: [

      ]
    },
    step1RenewAlt: {
      text: `Etape 1. Connectez-vous en suivant le bouton renouvellement`,
      speech: [

      ]
    },
    step1RenewButtonLabel: {
      text: `Bouton qui ouvre la pop-in du formulaire pour se connecter dans le cas d'un renouvellement.`,
      speech: [
      ]
    },
    step1ButtonLabel: {
      text: `Bouton qui ouvre la pop-in du formulaire pour une première demande.`,
      speech: [
      ]
    },
    step1: {
      text: ` Remplissez le formulaire ci-dessous afin d'initier votre demande d'aménagements`,
      speech: [
        { speech: `Etape une. Remplissez le formulaire ci-dessous afin d'initier votre demande d'aménagements ou connectez vous sur votre espace pour faire une nouvelle demande`, queue: false }
      ]
    },
    step1Renew: {
      text: ` Connectez-vous et renouvelez vos demandes`,
      speech: [
        { speech: `Etape une. Connectez-vous et renouvelez vos demandes`, queue: false }
      ]
    },

    step1More: {
      text: `
Cette demande sera à renouveler chaque année.
      

Si vous souhaitez recevoir des informations de la part de la Mission Accueil Handicap (invitations à des événements, offres de stages et d’emplois…), veuillez cocher la case correspondante


Après avoir envoyé votre formulaire en ligne n'oubliez pas de prendre rendez-vous avec le médecin universitaire: CECILE MASSET 
`,
      speech: [
        { speech: `Cette demande sera à renouveler chaque année.`, queue: true },
        { speech: `Si vous souhaitez recevoir des informations de la part de la Mission Accueil Handicap tels que des invitations à des événements, des offres de stages, d’emplois, veuillez cocher la case correspondante.`, queue: true },
        { speech: `Après avoir envoyé votre formulaire en ligne, n'oubliez pas de prendre rendez-vous avec le médecin universitaire: Cécille MASSET (voir étape numéro 2).`, queue: true }

      ]
    },
    step2TabButton: {
      text: `Etape 2`,
      speech: [

      ]
    },
    step2: {
      text: `
PRENEZ RDV AVEC
LE MEDECIN UNIVERSITAIRE :
  CECILE MASSET
          `,
      speech: [
        { speech: `Etape 2. Après avoir transmis votre demande à l'étape une. Prenez rendez-vous avec le médecin universtitaire: Cécile MASSET. Le numéro du standard téléphonique est le 03, 28, 04, 02, 40. `, queue: false }
      ]
    },
    step2Suite: {
      text: `
03.28.04.02.40
CPSU, 47 bis Rue du Port 59000 Lille
          `,
      speech: [
      ]
    },
    step2More: {
      text: `
Le Dr Masset est le médecin universitaire agréé par la MDPH.


Le médecin émet un avis sur les aménagements demandés et réalise des préconisations qui seront transmises à l'établissement cible.


Lors du RDV n'oublie pas de venir avec un document médical prouvant la pathologie.

      `,
      speech: [
        { speech: `Le Dr Masset, est le médecin universitaire agréé par la M,D,P,H.`, queue: true },
        { speech: `Le médecin émet un avis sur les aménagements demandés, et réalise des préconisations qui seront transmises à l'établissement cible.`, queue: true },
        { speech: `Lors du rendez-vous n'oubliez pas de venir avec un document médical prouvant la pathologie.`, queue: true }
      ]
    },
    step3TabButton: {
      text: `Etape 3`,
      speech: [

      ]
    },
    step3: {
      text: `
L'établissement prend une décision au sujet des aménagements lors des cours et examens et vous informe   
          `,
      speech: [
        { speech: `Etape 3. L'établissement prend une décision au sujet des aménagements, lors des cours et examens, et vous informe`, queue: true },
        { speech: ` Les propositions des établissements vous seront envoyées par courriel mail. Pensez à bien vérifier votre adresse email lors de l'inscription (cf étape 1).`, queue: true }
      ]
    }
    ,
    step3More: {
      text: `
Les propositions des établissements vous seront envoyées 
par courriel mail.

Pensez à bien vérifier votre adresse email lors de l'inscription.
 `,
      speech: [
      ]
    },
    step3Title: {
      text: `Décision de l'établissement`,
      speech: [

      ]
    },
    step3Alt: {
      text: `Step 3. Décision de l'établissement`,
      speech: [

      ]
    },
    signUpButton: {
      text: `Première demande`,
      speech: [
      ]
    },
    renewButton: {
      text: `Renouvellement`,
      speech: [
      ]
    },


  },

}

