import React, { useContext, useEffect, useState } from 'react';
import { useTour } from '@reactour/tour';
// import Section from './Section';
import Button from '@mui/material/Button';
import { KReactTourContext } from "../../stores/KReactTourProvider";
import IconButton from '@mui/material/IconButton';
import { TrainRounded } from '@material-ui/icons';
import { KMUserContext } from 'stores/KMUserProvider';
import { useLocation } from 'react-router-dom';
import { Grid } from '@mui/material';

export default function TourDemo() {
  const { setIsOpen, setSteps, setCurrentStep, steps, setIsTourOpen, currentStep } = useTour();
  const { reactTours } = useContext(KReactTourContext)
  const { user } = useContext(KMUserContext)
  const [flagShowButtonReactTour, setFlagShowButtonReactTour] = useState(false)
  let location = useLocation();

  useEffect(() => {
    function keyHandling(e) {
      if (e.keyCode === 75) {
        e.preventDefault();
        setIsOpen(true);
      }
    }
    window.addEventListener('keyup', keyHandling);
    return () => window.removeEventListener('keyup', keyHandling);
  }, [setIsOpen]);

  useEffect(() => {
    if (currentStep && currentStep === steps.length - 1) {
      setTimeout(() => {
        setIsOpen(false);
      }, 5000);
    }
  }, [currentStep])

  const handleSetSteps = () => {
    if (!user || !user.aclCode || !reactTours.length) return;
    setCurrentStep(0);
    const tour = reactTours.find(
      (reactTour) => {
        let regex = new RegExp(reactTour.reactTourPath)
        if (
          regex.test(location.pathname) &&
          reactTour.reactTourRole === user.aclCode
        ) return reactTour
      }

    );
    if (tour) {
      let stepWithHTMLs = JSON.parse(tour.reactTourJSON).steps.map(step => {
        step.content = <div dangerouslySetInnerHTML={{ __html: step.content }} />
        step.selector = xPathToElement(step.selector) ? xPathToElement(step.selector) : step.selector.includes("/html/") ? null : step.selector
        return step
      })
      setSteps(stepWithHTMLs);
      debugger
    } else {
      setSteps([])
    }
    setTimeout(() => {
      setIsOpen(true)
    }, 200);
  }
  const xPathToElement = (xpath) => {
    try {
      const result = document.evaluate(
        xpath,
        document,
        null,
        XPathResult.FIRST_ORDERED_NODE_TYPE,
        null
      );
      const node = result.singleNodeValue;
      if (node) {
        return node;
      } else {
        console.error(`No element found for XPath: ${xpath}`);
        return null;
      }
    } catch (error) {
      console.error(`Error evaluating XPath: ${xpath}`, error);
      return null;
    }
  };
  useEffect(() => {
    if (!user || !user.aclCode || !reactTours.length) return;
    if (!!reactTours.find((reactTour) => {
      let regex = new RegExp(reactTour.reactTourPath)
      if (
        regex.test(location.pathname) &&
        reactTour.reactTourRole === user.aclCode
      ) return reactTour
    }) === true) {
      setTimeout(() => {
        setFlagShowButtonReactTour(true)
      }, 1000);
    } else setFlagShowButtonReactTour(false)
  }, [user, location.pathname])

  return (
    <>
      {
        (!!flagShowButtonReactTour === true) && (
          <Button style={{ color: "#F4F4F4", borderColor: "#F4F4F4" }} variant="outlined" onClick={() => handleSetSteps()} startIcon={<TrainRounded />}>
            Besoin d'aide ?
          </Button>
        )
      }
    </>
  );
}
