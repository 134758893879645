const rbacRules = {
  KM_NO_RIGHTS: {
    static: [
    ]
  },
  KM_STUDENT: {
    static: [
      'views/KMCareFolderView/KMCareFolderView:visit',
      'views/KMCareFolderView/KMCareFolderStudent:visit',
      'layout/Dashboard/Dashboard:visit',
      'views/UserProfile/UserProfile:visit',
      'views/Infos/info:visit',
      'careFolder:duplicate',
      'careFolder:save',
    ],
    dynamic: {
      'userDetail:socialNetworkChoosingPreference': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },

      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:delete': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'views/KMProfilDetailView/KMProfilDetailView:visit': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  KM_CHARGEE_DE_MISSION_VIE_ETUDIANTE: {
    static: [
      'views/KMCareFolderListView/KMCareFolderListView:visit',
      'careFolder:save',
      'careFolderView:transmission',
      'views/KMCareFolderView/KMCareFolderView:visit',
      'careFolder:duplicate',
      'careFolder:archive',
      'careFolder:changeValidityTimeRange',
      'gabarit:chooser',
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/Dashboard:visit',
      'careFolder:create',
      // 'views/stats/userAwaitingForAccreditation',
      'views/stats/userStatsNbAll',
      'views/stats/stockageStatsWeight',
      'views/KMScheduledBroadcastListView/KMScheduledBroadcastListView:visit',
      'views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView:visit',

      'views/UserProfile/UserProfile:visit',
      // 'views/NotificationsPage/NotificationsPage:visit',
      // 'views/ApiListView/ApiListView:visit',
      'views/KMUserManagementView/KMUserManagementView:visit',
      'scheduledBroadcast:archive',
      'scheduledBroadcast:unArchive',
      'scheduledBroadcastDetailSegment:change',
      'scheduledBroadcast:activate',
      'scheduledBroadcast:desactivate',
      'scheduledBroadcast:create',
      'scheduledBroadcast:download',
      'scheduledBroadcastDetail:save',
      'userDetail:socialNetworkChoosingPreference',
      'userDetail:save',
      'userDetail:delete',
      'userDetail:expirePassword',
      'userDetail:forceResetPassword',
      // 'demo',
      'views/KMListEstablishmentView/KMListEstablishmentView:visit',
      'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
      'insertNewDoc:update',
      'establishmentDetail:save',
      'establishment:archive',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'views/notification',
      'views/Infos/info:visit',
      'views/Rapport/rapport:visit'
    ],
    dynamic: {
      'userDetail:socialNetworkChoosingPreference': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      // 'userDetail:delete': ({ userId, eventOwnerId }) => {
      //   if (!userId || !eventOwnerId) return false
      //   return userId === eventOwnerId
      // },
    }
  },
  KM_MEDECIN: {
    static: [
      'views/KMCareFolderListView/KMCareFolderListView:visit',
      'layout/Dashboard/Dashboard:visit',
      'careFolder:save',
      'careFolder:duplicate',
      'careFolder:archive',
      'careFolder:create',
      'careFolder:changeValidityTimeRange',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'views/Dashboard/Dashboard:visit',
      'views/Infos/info:visit',
      'views/KMCareFolderView/KMCareFolderView:visit',

    ],
    dynamic: {
      'userDetail:socialNetworkChoosingPreference': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
    }
  },
  
  KM_MAH: {
    static: [
      'views/KMCareFolderListView/KMCareFolderListView:visit',
      'layout/Dashboard/Dashboard:visit',
      'careFolderView:transmission',
      'careFolder:save',
      'gabarit:chooser',
      'careFolder:create',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/UserProfile/UserProfile:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'views/Dashboard/Dashboard:visit',
      'views/Infos/info:visit',
      'views/notification',
      'careFolder:archive',
      'views/KMCareFolderView/KMCareFolderView:visit',

    ],
    dynamic: {
      'userDetail:socialNetworkChoosingPreference': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      'userDetail:save': ({ userId, eventOwnerId }) => {
        if (!userId || !eventOwnerId) return false
        return userId === eventOwnerId
      },
      // 'userDetail:delete': ({ userId, eventOwnerId }) => {
      //   if (!userId || !eventOwnerId) return false
      //   return userId === eventOwnerId
      // },
    }
  },
 
  KM_SUPERVISOR: {
    static: [
      'views/KMCareFolderListView/KMCareFolderListView:visit',
      'views/KMCareFolderView/KMCareFolderView:visit',
      'careFolder:save',
      'careFolderView:transmission',
      'careFolder:duplicate',
      'careFolder:archive',
      'careFolder:changeValidityTimeRange',
      'gabarit:chooser',
      'layout/Dashboard/Dashboard:visit',
      'views/Dashboard/Dashboard:visit',
      'careFolder:create',
      // 'views/stats/userAwaitingForAccreditation',
      'views/stats/userStatsNbAll',
      'views/stats/stockageStatsWeight',
      'views/KMScheduledBroadcastListView/KMScheduledBroadcastListView:visit',
      'views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView:visit',

      'views/UserProfile/UserProfile:visit',
      // 'views/NotificationsPage/NotificationsPage:visit',
      // 'views/ApiListView/ApiListView:visit',
      'views/KMUserManagementView/KMUserManagementView:visit',
      'scheduledBroadcast:archive',
      'scheduledBroadcast:unArchive',
      'scheduledBroadcastDetailSegment:change',
      'scheduledBroadcast:activate',
      'scheduledBroadcast:desactivate',
      'scheduledBroadcast:create',
      'scheduledBroadcast:download',
      'scheduledBroadcastDetail:save',
      'userDetail:socialNetworkChoosingPreference',

      'userDetail:save',
      'userDetail:delete',
      'userDetail:expirePassword',
      'userDetail:forceResetPassword',
      // 'demo',
      'views/KMListEstablishmentView/KMListEstablishmentView:visit',
      'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
      'insertNewDoc:update',
      'establishmentDetail:save',
      'establishment:archive',
      'views/KMProfilDetailView/KMProfilDetailView:visit',
      'views/KMProfilDetailView/AdvancedFeilds:edit',
      'views/KMProfilDetailView/changeEstablishment:view',
      'views/notification',
      'views/Infos/info:visit',
      'views/Rapport/rapport:visit'
    ],
    dynamic: {
     
    }

  },

}

export default rbacRules
