import clsx from 'clsx'

import React, { useState, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { NavLink, useLocation } from 'react-router-dom'
// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles'
import { Drawer, Hidden, Collapse, List, ListItem, ListItemIcon, ListItemAvatar, Tooltip, ListItemText, Avatar, Icon } from '@material-ui/core'
import { PersonRounded, ExpandLessRounded, ExpandMoreRounded, ExitToAppRounded } from '@material-ui/icons'
// core components
import HeaderLinks from 'components/Header/HeaderLinks.jsx'
import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle.jsx'
import config from 'config'
import superagent from 'superagent'
import { withUser } from 'stores/KMUserProvider'
import KMCan from 'components/KMCan/KMCan'
import { withClient } from 'stores/KMClientProvider'
import { RibbonContainer, Ribbon } from "react-ribbons"
import UseDynamicIcon from 'components/hooks/UseDynamicIcon'
import TourDemo from 'components/TourDemo/TourDemo'
import { Grid } from '@mui/material'
const Sidebar = ({ ...props }) => {
  let location = useLocation()
  const [openUser, setOpenUser] = useState(false)

  const handleLogOut = async (event) => {
    const oauthLogoutUrl = config.oauth.logout.url
    try {
      await superagent
        .get(oauthLogoutUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
    } catch (e) { }
    // let responseKeepapi = apiResponseNetwork.body
    // if (responseKeepapi.code !== 'ok') return
    try {
      function removeCookie(name, path, domain) {
        let str = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
        document.cookie = str
      }
      removeCookie("kmSession", '/', `.${config.mainDomainName}`);
      removeCookie("", '/', `.${config.mainDomainName}`);

    } catch (e) { }
    window.localStorage.clear()
     window.location.href = `https://${config.mainDomainName}/logout`
  }

  // verifies if routeName is the one active (in browser input)
  function activeRoute(routeName) {
    return routeName && routeName.test(location.pathname) === true
    // return props.location.pathname.indexOf(routeName) > -1
  }
  const { classes, color, client, image, logoText, routes, user = {} } = props
  var links = (
    <>

      <List className={classes.list}>
        {routes.map((route, key) => {
          if (route.redirect) return null
          var activePro = ' '
          var listItemClasses
          if (route.path === '/upgrade-to-pro') {
            activePro = classes.activePro + ' '
            listItemClasses = classNames({
              [' ' + classes[color]]: true,
              [' ' + classes.listItemClassesFullWidth]: props.isContainerFullWidth && activeRoute(route.pathPattern)

            })
          } else {
            listItemClasses = classNames({
              [' ' + classes[color]]: activeRoute(route.pathPattern)
            })
          }
          const whiteFontClasses = classNames({
            [' ' + classes.whiteFont]: activeRoute(route.pathPattern)
          })
          return (
            <KMCan
              key={"links_" + key}
              perform={route.routeListRBAC || 'null'}
              yes={() => (

                <NavLink
                  to={route.routePath.replace(/:userId/, props.user.id)}
                  className={activePro + classes.item}
                  activeClassName="active"
                  key={key}
                >
                  <ListItem button className={classes.itemLink + listItemClasses} alignItems="flex-start">
                    <ListItemIcon className={classes.itemIcon + whiteFontClasses}>
                      {typeof route.routeIcon === 'string' ? (
                        <UseDynamicIcon
                          iconName={route.routeIcon || "ReportGmailerrorredRounded"}
                        />
                      ) : (
                        <></>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={props.isContainerFullWidth !== true ? route.routeReactSideBarActiveMenuPattern : <Fragment>&nbsp;</Fragment>}
                      className={classes.itemText + whiteFontClasses}
                      disableTypography={true}
                    />
                  </ListItem>
                </NavLink>

              )} />
          )
        })}
      </List>
    </>
  )
  const brand =
    config.ribbonMessage ? (
      <div className={classes.logoRibbon} >
        <RibbonContainer >
          <Ribbon side={'left'} backgroundColor="#ab47bc" color="#f0f0f0" fontFamily="Arial" size={'normal'} type={'corner'}>
            <b>{config.ribbonMessage}</b>
          </Ribbon>
          <a href="" className={classes.logoLink}>
            <div className={clsx({ [classes.logoImage]: true, [classes.logoImageFullWidth]: props.isContainerFullWidth })}>
              {/* <img src={logo} alt="logo" className={classes.img} /> */}
              {client.kmClientLogo &&
                <img src={client.kmClientLogo} alt={client.kmClientLogo} width={props.isContainerFullWidth ? 125 : 180} />
              }
            </div>
            {logoText}
          </a>

        </RibbonContainer>
      </div >
    ) : (
      <div className={classes.logo} >

        <a href="" className={classes.logoLink}>
          <div className={clsx({ [classes.logoImage]: true, [classes.logoImageFullWidth]: props.isContainerFullWidth })}>
            {/* <img src={logo} alt="logo" className={classes.img} /> */}
            {client.kmClientLogo &&
              <img src={client.kmClientLogo} alt={client.kmClientLogo} width={props.isContainerFullWidth ? 125 : 180} />
            }
          </div>
          {logoText}
        </a>

      </div >
    )
  let userFirstAndLastName = `${user.firstName || ''} ${user.lastName || ''}`

  return (
    <div>
      <Hidden mdUp implementation="css">
        <Drawer
          variant="temporary"
          anchor="right"
          open={props.open}
          classes={{
            paper: classes.drawerPaper
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true // Better open performance on mobile.
          }}
        >
          {brand}

          <div className={classes.sidebarWrapper}>
            <HeaderLinks />
            <div className={classes.user}>
              <ListItem button onClick={() => setOpenUser(!openUser)}>
                {user.hasOwnProperty('avatarDocData') === true &&
                  <ListItemAvatar>
                    <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                      <Avatar alt={user.firstName} src={user.avatarDocData} className={classes.avatar} />
                    </Tooltip>
                  </ListItemAvatar>
                }
                {user.hasOwnProperty('avatarDocData') === false &&
                  <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                    <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                      <PersonRounded />
                    </Tooltip>
                  </ListItemIcon >
                }
                <ListItemText primary={userFirstAndLastName}
                  className={classes.itemText + ' ' + classes.whiteFont}
                  disableTypography={true} />
                {openUser ? <ExpandLessRounded className={classes.itemText + ' ' + classes.whiteFont} /> : <ExpandMoreRounded className={classes.itemText + ' ' + classes.whiteFont} />}
              </ListItem>
              <Collapse in={openUser} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  <ListItem button >
                    <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                      <ExitToAppRounded className={classes.itemText + ' ' + classes.whiteFont} />
                    </ListItemIcon>
                    <ListItemText primary={'Déconnexion'}
                      onClick={handleLogOut}
                      className={classes.itemText + ' ' + classes.whiteFont}
                      disableTypography={true} />                </ListItem>
                </List>
              </Collapse>
            </div>

            {links}
          </div>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
          <Grid container justifySelf="center" fullWidth>
            <TourDemo />
          </Grid>
        </Drawer>
      </Hidden>
      <Hidden smDown implementation="css">
        <Drawer
          anchor="left"
          variant="permanent"
          open
          classes={{
            paper: clsx({
              paper: classes.drawerPaper,
              [classes.drawerPaper]: true,
              [classes.drawerOpen]: !props.isContainerFullWidth,
              [classes.drawerClose]: props.isContainerFullWidth,
            }),
          }}
        >
          {brand}
          <div className={classes.user}>
            <ListItem button onClick={() => setOpenUser(!openUser)}>
              {user.hasOwnProperty('avatarDocData') === true &&
                <ListItemAvatar className={clsx({ [classes.listItemFullWidth]: props.isContainerFullWidth })}>
                  <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                    <Avatar alt={user.firstName} src={user.avatarDocData} className={classes.avatar} />
                  </Tooltip>
                </ListItemAvatar>
              }
              {user.hasOwnProperty('avatarDocData') === false &&
                <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                  {/* <Icon alt={user.firstName} title={`connecté avec l'adresse ${user.email} ${user.aclCode}`} className={'fa fa-user'} color="primary" /> */}
                  <Tooltip title={`connecté avec l'adresse ${user.email} ${user.aclCode}`}>
                    <PersonRounded />
                  </Tooltip>
                </ListItemIcon>
              }
              {props.isContainerFullWidth === false &&
                <ListItemText primary={userFirstAndLastName}
                  className={classes.itemText + ' ' + classes.whiteFont}
                  disableTypography={true} />
              }
              {openUser ? <ExpandLessRounded className={classes.itemText + ' ' + classes.whiteFont} /> : <ExpandMoreRounded className={classes.itemText + ' ' + classes.whiteFont} />}
            </ListItem>
            <Collapse in={openUser} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItem button className={classes.nested}>
                  <ListItemIcon className={classes.itemIcon + ' ' + classes.whiteFont}>
                    <ExitToAppRounded />
                  </ListItemIcon>
                  <ListItemText primary={'Déconnexion'}
                    onClick={handleLogOut}
                    className={classes.itemText + ' ' + classes.whiteFont}
                    disableTypography={true} />
                </ListItem>
              </List>
            </Collapse>
          </div>
          {/* <div className="user">
            <div className="photo">
              <img src="/light-bootstrap-dashboard-pro-react/static/media/default-avatar.f653841f.png"
                alt="Avatar" />
            </div>
            <div className="info">
              <a>
                <span>Tania Andrew<b className="caret"></b>
                </span>
              </a>
              <ul className="nav collapse" style={{height:"0px"}}>
                <li>
                  <a>
                    <span className="sidebar-mini">MP</span>
                    <span className="sidebar-normal">My Profile</span>
                  </a>
                </li>
                <li>
                  <a>
                    <span className="sidebar-mini">EP</span>
                    <span className="sidebar-normal">Edit Profile</span>
                    </a>
                    </li>
                    <li>
                      <a>
                        <span className="sidebar-mini">S</span>
                        <span className="sidebar-normal">Settings</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div> */}
          <div className={classes.sidebarWrapper}>{links}</div>
          <Grid container justifyContent="center" fullWidth style={{zIndex:"9999999", marginBottom:"15px"}}>
            <TourDemo />
          </Grid>
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: 'url(' + image + ')' }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  )
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired
}

export default withStyles(sidebarStyle)(withUser(withClient(Sidebar)))
