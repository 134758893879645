import { withWidth } from '@material-ui/core'
import clsx from 'clsx'
import React, { Suspense, lazy, useContext, useEffect, useState } from 'react'
import KMProgressBar from 'components/KMProgressBar/KMProgressBar'

import PropTypes from 'prop-types'
import { Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom'
// creates a beautiful scrollbar
// import PerfectScrollbar from 'perfect-scrollbar'
// import 'perfect-scrollbar/css/perfect-scrollbar.css'
import withStyles from '@material-ui/core/styles/withStyles'
import Header from 'components/Header/Header.jsx'
import Footer from 'components/Footer/Footer.jsx'
import Sidebar from 'components/Sidebar/Sidebar.jsx'
import dashboardRoutes from 'routes/dashboard.jsx'
import dashboardStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx'
import image from 'assets/img/sidebar-6.jpg'
import KMCan from 'components/KMCan/KMCan'
import { withUser } from 'stores/KMUserProvider'
import { withLangStore } from "stores/KLangProvider.js";
import { KMCanChecker } from 'components/KMCan/KMCan'
import { withClient } from 'stores/KMClientProvider'

// import { CheckPermissionUtil } from 'components/KMCan/CheckPermission'
// import { CheckRoleUtil } from 'components/KMCan/CheckRole'
import { KMInfraContext } from 'stores/KMInfraProvider'
import config from "config";
const switchRoutes = (user, infraStore) => {
  const myConfig = config;
  return (
    <Suspense>
      <Routes>
        {infraStore.privateRoutes
          .filter((privateRoute) => privateRoute.routeReactComponent)
          .sort(
            (privateRouteCurrent, privateRouteNext) =>
              privateRouteCurrent.routeOrder - privateRouteNext.routeOrder
          )
          .map((privateRoute, key) => {
            let {
              routeListRBAC,
              routeLabel,
              routeEntityIdName,
              routePath,
              routeReactComponent,
              routeJSONParam = {},
            } = privateRoute;
            if (!routePath.startsWith("/")) {
              console.log(
                "missconfiguration of config file. must begin with /. see : ",
                routePath
              );
            }
            try {
              routeJSONParam = JSON.parse(routeJSONParam);
            } catch (error) {

              console.log("missConfiguration of routeJSONParam routePath :", routePath, " ", error);
            }
            let {
              apiList,
              detailPanelReactComponent,
              detailPanelReactComponentProp,
              formCode,
              multipleFormCodes = [],
              computeWhenChanges = [],
              hiddenFieldType,
              filterCode,
              reportCode,
              staticNavigations = [],
              staticTabNavigation = "",
              staticHelp = "",
              detailPanelExpandIcon = "",
              detailPanelCollapseIcon = '',
              routeCallbackOnAction = '',
              initialSteps = [],
              componentProps = {},
              flagIsKanban = false,
              flagRouteLabel = true,
            } = routeJSONParam || {};
            try {
              if (apiList) {
                apiList = eval("myConfig.api." + apiList + ".url");
              }
            } catch (error) {
              console.log(
                "missconfiguration of config file. see : ",
                routePath,
                routeJSONParam,
                error
              );
            }
            let canView = true
            // let canView = CheckPermissionUtil({
            //   sessionUserStore,
            //   perform: routeListRBAC,
            //   debug: false,
            // });
            // let routeRBACs =
            //   routeListRBAC && routeListRBAC.indexOf(",") > 0
            //     ? routeListRBAC.split(",").map((rbac) => rbac.trim())
            //     : [routeListRBAC];
            // if (!!canView === false) {
            //   canView = CheckRoleUtil({
            //     sessionUserStore,
            //     allowedRoles: routeRBACs,
            //     debug: false,
            //   });
            // }
            if (!!canView === false) return null;
            let MyComponent = lazy(() => import(`views/${routeReactComponent}`));
            return (
              <Route
                path={routePath}
                exact
                element={
                  // <PFormProvider formCode={formCode}>
                  <MyComponent
                    apiList={apiList}
                    detailPanelReactComponent={detailPanelReactComponent}
                    detailPanelReactComponentProp={detailPanelReactComponentProp}
                    hiddenFieldType={hiddenFieldType}
                    formCode={formCode}
                    multipleFormCodes={multipleFormCodes}
                    computeWhenChanges={computeWhenChanges}
                    filterCode={filterCode}
                    reportCode={reportCode}
                    routeLabel={routeLabel}
                    routeEntityIdName={routeEntityIdName}
                    staticNavigations={staticNavigations}
                    staticTabNavigation={staticTabNavigation}
                    staticHelp={staticHelp}
                    detailPanelExpandIcon={detailPanelExpandIcon}
                    detailPanelCollapseIcon={detailPanelCollapseIcon}
                    routeCallbackOnAction={routeCallbackOnAction}
                    initialSteps={initialSteps}
                    sessionUserStore={user}
                    flagRouteLabel={flagRouteLabel}
                    componentProps={componentProps}
                    flagIsKanban={flagIsKanban}
                  />
                  // </PFormProvider>
                }
                key={key}
              />
            )
          })}
      </Routes>
    </Suspense>
  )
}
const App = (props) => {
  let location = useLocation()
  let navigate = useNavigate()
  const { infraStore, infraStoreRightPermissionDenied } = useContext(KMInfraContext);
  const [mobileOpen, setMobileOpen] = useState(false)
  const [isContainerFullWidth, setIsContainerFullWidth] = useState(false)
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  };
  useEffect(() => {
    let { width } = props
    setIsContainerFullWidth(location.pathname.match(/\/report\/[0-9]+/) && width !== 'sm' && width !== 'xs')
  }, [props.width])
  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false)
    }
  }

  useEffect(() => {
    if (location.pathname === "/" && props && props.user && props.user.aclCode) {
      if (props.user.aclCode === "KM_STUDENT") navigate("/careFolder")
      else if (props.user.aclCode !== "KM_STUDENT") navigate("/dashboard");
    }
  }, [props.user]);

  // componentDidUpdate(e) {
  //   // if (e.history.location.pathname !== e.location.pathname) {
  //   //   // refs.mainPanel.scrollTop = 0;
  //   //   if (mobileOpen) {
  //   //     setState({ mobileOpen: false })
  //   //   }
  //   // }
  // }
  useEffect(() => {
    window.addEventListener('resize', resizeFunction)
    // props.initLangStore().then(data => {

    // })
    return () => {
      window.removeEventListener('resize', resizeFunction)
    }
  }, [])

  const { classes, ...rest } = props
  let textNoActifUser

  let dashboardRoutesForSidebar = []
  if (infraStore.privateRoutes) {
    dashboardRoutesForSidebar = infraStore.privateRoutes.filter(route => !!route.routeFlagDisplayInReactSideBarMenu)
  }
  if (dashboardRoutesForSidebar.length > 0)
    return (
      <KMCan
        perform="layout/Dashboard/Dashboard:visit"
        yes={() => (
          <div className={classes.wrapper}>
            <Sidebar
              isContainerFullWidth={!!isContainerFullWidth}
              routes={dashboardRoutesForSidebar}
              logoText={''}
              logo={props.client.kmClientLogo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color="blue"
              {...rest}
            />
            <div className={
              clsx({
                [classes.mainPanel]: true,
                [classes.mainPanelFullWidth]: !!isContainerFullWidth,
              })
            } >              <KMProgressBar />
              <Header
                routes={dashboardRoutes}
                handleDrawerToggle={handleDrawerToggle}
                {...rest}
              />
              {!!isContainerFullWidth === false ? (
                <div className={classes.content}>
                  <div className={classes.container}>{switchRoutes(props.user, infraStore)}</div>
                </div>
              ) : (
                <div style={{ flexGrow: '1 !important', width: 'auto' }}>{switchRoutes(props.user, infraStore)}</div>
              )}
              <Footer />
            </div>
          </div>
        )}
        no={() =>
          <div className={classes.wrapper}>
            <Sidebar
              isContainerFullWidth={!!isContainerFullWidth}
              routes={[]}
              logoText={''}
              logo={props.client.kmClientLogo}
              image={image}
              handleDrawerToggle={handleDrawerToggle}
              open={mobileOpen}
              color="blue"
              {...rest}
            />
            <div className={classes.mainPanel} >
              <div className={classes.content}>
                <div className={classes.container}>
                  <div>
                    {textNoActifUser}
                  </div>
                </div>
              </div>
              <Footer />
            </div>
          </div>

        }
      />
    )
  else return <></>

}


export default withWidth()(withStyles(dashboardStyle)(withUser(withLangStore(withClient(App)))))
