
import {useContext, useEffect, useState} from "react"
import { redirect, Route, Routes, useLocation } from 'react-router-dom';
import { oauth } from 'oauth/oauth'
import LayoutDeuxMilleVingtQuatre from 'layouts/LayoutDeuxMilleVingtQuatre'
import React from "react";
import { motion } from "framer-motion";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import theme from "assets/jss/theme";
import { KMUserProvider } from "stores/KMUserProvider";
import { KLangProvider } from "stores/KLangProvider";
import { KMScheduledBroadcastProvider } from "stores/KMScheduledBroadcastProvider";
import { KMProfilProvider } from "stores/KMProfilProvider";
import { KMCareFolderProvider } from "stores/KMCareFolderProvider";
import { KMClientProvider } from "stores/KMClientProvider";
import { KMEstablishmentProvider } from "stores/KMEstablishmentProvider";
import { Worker } from '@react-pdf-viewer/core';
import KReactTourProvider from "../stores/KReactTourProvider";



const PrivateRoute = (props) => {
    let [trust, setTrust] = useState('red') // pas encore fetcher on ne passe pas
    // let [steps, setSteps] = useState('red')

    useEffect(() => { openPrivateRoute() }, [])



    // const disableBody = (target) => disableBodyScroll(target);
    //
    // const enableBody = (target) => enableBodyScroll(target);
    // const isClose = false;
    // localStorage.setItem('enableTour', false);
    const openPrivateRoute = async () => {

        const isAuthenticated = await oauth.authenticate()
        if (isAuthenticated === true) setTrust('green')
        else {
            setTrust('orange')
            window.location.href = '/logout?redirectToReferrer='+window.location.pathname
            // return redirect()
        }
    }
    if (trust === 'red') return null
    if (trust === 'orange') return null
    // la personne est connectée on lance le layout admin
    if (trust === 'green')
        return (
            <motion.div
                initial="initial"
                animate="in"
                exit="out"
                variants={{
                    initial: {
                        opacity: 0,
                    },
                    in: {
                        opacity: 1,
                    },
                    out: {
                        opacity: 0,
                    },
                }}
                transition={{
                    duration: 0.5,
                    delay: 0.005,
                }}
            >
                <ThemeProvider theme={createTheme(theme)}>
                    <KMScheduledBroadcastProvider>
                        <KMProfilProvider>
                            <KMCareFolderProvider>
                                <KMClientProvider>
                                    <KMUserProvider>
                                        <KReactTourProvider>
                                        <KLangProvider>
                                            {/* <KMAssociationProvider> */}
                                            <KMEstablishmentProvider>
                                                <Worker workerUrl="/pdf/pdf.worker.min.js">
                                                <LayoutDeuxMilleVingtQuatre {...props} />
                                                </Worker>
                                            </KMEstablishmentProvider>
                                            {/* </KMAssociationProvider> */}
                                        </KLangProvider>
                                        </KReactTourProvider>
                                    </KMUserProvider>
                                </KMClientProvider>
                            </KMCareFolderProvider>
                        </KMProfilProvider>
                    </KMScheduledBroadcastProvider>
                </ThemeProvider>
            </motion.div>
        )
}
export default PrivateRoute
