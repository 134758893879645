import React, { createContext, useState, useEffect, useContext } from 'react';
import { TourProvider, useTour } from "@reactour/tour"; // Importer TourProvider et useTour
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import steps from "../steps";
import config from 'config'
import { restIO } from 'utils/io'
import { useLocation } from 'react-router-dom';
import { KMUserContext } from "./KMUserProvider";

export const KReactTourContext = createContext({
    reactTourSteps: [],
    reactTourHide: false,
    isTourOpen: false,
    isShowingMore: false,
    enableBody: () => { },
    disableBody: () => { },
    closeTour: () => { },
    openTour: () => { },
    setSteps: () => { },
    reactTours: [],
    setReactTourSteps: []

})

const KReactTourProvider = (props) => {
    const [reactTourSteps, setReactTourSteps] = useState([])
    const [reactTours, setReactTours] = useState([])
    const [reactTourHide, setReactTourHide] = useState(true)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [isShowingMore, setIsShowingMore] = useState(false)
    const [loading, setLoading] = useState(false)
    const { setIsOpen, setSteps, steps, setCurrentStep, currentStep } = useTour();
    const [disableKeyboardNav, setDisableKeyboardNav] = useState(false)
    const { user } = useContext(KMUserContext)
    let location = useLocation();
    const disableBody = (target) => {
        disableBodyScroll(target);
    }

    const enableBody = (target) => {
        enableBodyScroll(target);
    }

    useEffect(() => {
        initReactTour()
    }, [])

    const closeTour = () => {
        setIsOpen(false)
    };

    const openTour = () => {
        if (!!loading === true) {
            setIsOpen(true)
        }
    };

    const initReactTour = async () => {
        setLoading(false)
        let endpoint = config.api.public.reactTour.url
        let responseKeepapi = await restIO({ endpoint, jwt: true })
        let { data: datas, code } = responseKeepapi
        if (code !== 'ok') {
            // return enqueueSnackbar(`Votre demande ne peux aboutir. 202202081038`, { variant: 'warning', })
        }
        if (datas.length > 0) {
            setReactTours(datas)
            setLoading(true)
            // setLoading(true)
            // datas.map((data) => {
            //     setReactTourSteps(JSON.parse(data.reactTourJSON).steps)
            // })


        }
    }

    useEffect(() => {
        setSteps([])
        if (!user || !user.aclCode || !reactTours.length) return;
        const tour = reactTours.find(
            (reactTour) => {
                let regex = new RegExp(reactTour.reactTourPath)
                if (
                    regex.test(location.pathname) &&
                    reactTour.reactTourRole === user.aclCode
                ) return reactTour
            }
        );
        if (tour) {
            let stepWithHTMLs = JSON.parse(tour.reactTourJSON).steps.map(step => {
                step.content = <div dangerouslySetInnerHTML={{ __html: step.content }} />
                return step
            })
            setSteps(stepWithHTMLs);
        } else {
            setSteps([])
        }
    }, [user]);


    if (!!loading === false && steps.length === 0) {
        return <></>
    }
    else {
        return (
            <KReactTourContext.Provider value={{
                reactTourSteps,
                setReactTourSteps,
                reactTours,
                reactTourHide,
                isTourOpen,
                isShowingMore,
                closeTour,
                openTour,
                disableBody,
                enableBody,
                setSteps,
                setCurrentStep,
                currentStep,
            }}>
                <TourProvider
                    steps={reactTourSteps}
                    afterOpen={disableBody}
                    beforeClose={enableBody}
                    onRequestClose={closeTour}
                    styles={{
                        badge: (base) => ({ ...base, background: '#5cb7b7' }),
                        dot: (base, { current }) => ({
                            ...base,
                            background: current ? '#5cb7b7' : '#ccc',
                        }),
                        popover: (base) => ({
                            ...base,
                            background: '#dedede',
                            borderRadius: 10,
                        }),
                    }}
                    scrollSmooth
                    disableKeyboardNavigation={disableKeyboardNav}
                >
                    {props.children}
                </TourProvider>
            </KReactTourContext.Provider>
        )
    }
}

export default KReactTourProvider

// export const withReactTourStore = Component => props => (
//     <KReactTourContext.Consumer>
//         {store => {
//             return (<Component {...props} {...store} />)
//         }}
//     </KReactTourContext.Consumer>
// )
