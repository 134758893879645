import React, { createContext, Component } from 'react'
import BackFr from 'lang/back.fr'

export const KLangContext = createContext({
  langStore: {}, // store all the lang details
  initLangStore: () => { },
})

export class KLangProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      langStore: {}, // store all the lang details
      initLangStore: this.initLangStore,
    }
    this.initLangStore = this.initLangStore.bind(this)
  }
  initLangStore = async () => {
    if (!("prefsCountryCode" in sessionStorage ))
        this.setState({ langStore: BackFr })
    else if (sessionStorage.prefsCountryCode && sessionStorage.prefsCountryCode.toLowerCase() === 'fr')
      this.setState({ langStore: BackFr })
   
  }
  render() {
    return (
      <KLangContext.Provider value={this.state}>
        {this.props.children}
      </KLangContext.Provider>
    )
  }
}
export const withLangStore = Component => props => (
  <KLangContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KLangContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withLang } from './components/KLangProvider/KLangProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.lang.firstName}!</h1>)
  }
}
export default withLang(TOTO)

 */
