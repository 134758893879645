import rules from '../../rbacRules'
import { withUser } from 'stores/KMUserProvider'

const check = (rules, role, action, data) => {
  if (!role) {
    // role is not present
    return false
  }
  const permissions = rules[role]
  if (!permissions) {
    // role is not present in the rules
    return false
  }

  const staticPermissions = permissions.static

  if (staticPermissions && staticPermissions.includes(action)) {
    // static rule not provided for action
    return true
  }

  const dynamicPermissions = permissions.dynamic

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[action]
    if (!permissionCondition) {
      // dynamic rule not provided for action
      return false
    }

    return permissionCondition(data)
  }
  return false
}

const KMCan = props => {
  let role = props.user ? props.user.aclCode : null

  return check(rules, role, props.perform, props.data)
    ? props.yes()
    : props.no()
}
/** new
 * exemple d'appel
 *       const eventDetailSaveACL = KMCanChecker({
            user,
            perform: 'eventDetail:save',
            data: {
                userId: user.id,
                eventOwnerId: eventStore.eventOwnerId
            }
        })
 * **/
export const KMCanChecker = (props) => {
  let role = props.user ? props.user.aclCode : props.sessionUserStore ? props.sessionUserStore.aclCode : null
  return check(rules, role, props.perform, props.data)
}

KMCan.defaultProps = {
  yes: () => null,
  no: () => null
}

export default withUser(KMCan)
