import { useEffect } from "react"
import { useParams } from "react-router-dom";

const Autolog = (props) => {
    let params = useParams()
    useEffect(() => {
        let stopRedirect = window.sessionStorage.getItem('stopRedirect') || false
        if (stopRedirect === true) {
            debugger
        }
        window.localStorage.setItem('JWT', params.token)
        let redirectToReferrerStr = window.localStorage.getItem('redirectToReferrer')
        let from = '/'
        if (redirectToReferrerStr) {
            try {
                let redirectToReferrer = JSON.parse(redirectToReferrerStr)
                let patternNoRedirectLoop = /^\/login|^\/logout/i
                if (!redirectToReferrer.pathname.match(patternNoRedirectLoop))
                    from = redirectToReferrer.pathname
            } catch (exception) { }
        }
        if (stopRedirect === false)
            window.location = from
        return <div />
    }, []);

}

export default Autolog
