import React, { createContext, Component } from 'react'
import config from '../config'
// import _ from 'lodash'
import superagent from 'superagent'

export const KMProgilContext = createContext({
  profilStore: {}, // store all the profil details
  updateProfilStore: () => { },
  initProfilStore: () => { },
  reloadProfilStore: () => { },
  profilStoreRightPermissionDenied: true
})

export class KMProfilProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profilStore: {}, // store all the profil details
      updateProfilStore: this.updateProfilStore,
      initProfilStore: this.initProfilStore,
      reloadProfilStore: this.reloadProfilStore,
      profilStoreRightPermissionDenied: true
    }
    this.updateProfilStore = this.updateProfilStore.bind(this)

    this.initProfilStore = this.initProfilStore.bind(this)
    this.reloadProfilStore = this.reloadProfilStore.bind(this)

  }
  reloadProfilStore = () => {
    this.initProfilStore(this.state.profilStore.userId)
  }
  updateProfilStore = (key, value, cb) => {
    let myProfilStore = this.state.profilStore // faster deep copy
    myProfilStore[key] = value
    this.setState({ profilStore: myProfilStore }, cb)
    // let profilStore = { ...this.state.profilStore }
    // profilStore[key] = value
    // this.setState({ profilStore })
  }
  initProfilStore = async (userId) => {
    let apirUrl = config.api.profilDetail.url
    apirUrl = apirUrl.replace('___PROFILID___', userId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apirUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return null
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {
      let profilStore = responseKeepapi.data[0]
      this.setState({ profilStore, profilStoreRightPermissionDenied: false })
     return  profilStore
    }
  }

  render() {
    return (
      <KMProgilContext.Provider value={this.state}>
        {this.props.children}
      </KMProgilContext.Provider>
    )
  }
}

export const withProfilStore = Component => props => (
  <KMProgilContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMProgilContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withProfil } from 'stores/KMProfilProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.profil.firstName}!</h1>)
  }
}
export default withProfil(TOTO)

 */
