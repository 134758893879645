import React, { createContext, useState, useEffect } from 'react'
import { restIO } from 'utils/io'
import config from 'config.js'
import { Grid, Stack, Zoom } from '@mui/material';
import { KTooltip } from 'components/KComponents/KTooltip';
import UseDynamicIcon from 'components/hooks/UseDynamicIcon';
import dayjs from 'dayjs';

export const KMInfraContext = createContext({
  infraStore: {},
  infraStoreRightPermissionDenied: true,
  getTypingIdBy: () => { },
  getTypingBy: () => { },
  getTypingsBy: () => { },
  formatTyping: () => { },
  formatTypingShortIcon: () => { },
})
const KMInfraProvider = (props) => {
  const [infraStore, setInfraStore] = useState({})
  const [infraStoreRightPermissionDenied, setInfraStoreRightPermissionDenied] = useState(true)
  useEffect(() => {
    
    initInfra()
  }, [])

  const getTypingIdBy = ({ typingCode = "" } = {}) => {
    if (typingCode !== "") {
      let needles = infraStore.typings.filter(typing => typing.typingCode === typingCode)
      if (needles.length === 1) return needles[0].typingId
    }
    return 0
  }
  const getTypingsBy = ({ typingTargetColumn = "" } = {}) => {
    if (typingTargetColumn !== "") {
      return infraStore.typings.filter(typing => typing.typingTargetColumn === typingTargetColumn)
    }
    return []
  }
  const getTypingBy = ({ typingId = 0, typingCode = "" } = {}) => {
    if (typingId !== 0) {
      let needles = infraStore.typings.filter(typing => typing.typingId === typingId)
      if (needles.length === 1) return needles[0]
    } else if (typingCode !== "") {
      let needles = infraStore.typings.filter(typing => typing.typingCode === typingCode)
      if (needles.length === 1) return needles[0]
    }
    return {}
  }
  const formatTyping = ({ typingId = 0, typing = null, flagShowLabel = true } = {}) => {
    if (!typing && typingId > 0) typing = getTypingBy({ typingId })
    let { typingMaterialIcons = [], typingMaterialIconColor, typingLabel, typingDescription } = typing || {}
    return (
      <KTooltip TransitionComponent={Zoom} arrow title={typingDescription} >
        <Grid container direction="row" alignItems="center" spacing="1">
          {typingMaterialIcons.map(typingMaterialIcon =>
            <Grid item>{typingMaterialIcon && <UseDynamicIcon iconName={typingMaterialIcon} style={{ color: typingMaterialIconColor }} fontSize="small" />}</Grid>
          )}
          {!!flagShowLabel === true && <Grid item> {typingLabel}</Grid>}
        </Grid>
      </KTooltip>
    )
  }
  const formatTypingShortIcon = ({ showUpdateBy = null, showUpdateDatetime = null, typingId = 0, typing = null, flagShowLabel = true, flagShowTooltipLabel = false, flagShowTooltip = false, flagShowTypingDescription = false, fontSize = "", dynamicIconSx } = {}) => {
    if (!typing && typingId > 0) typing = getTypingBy({ typingId })
    let { typingMaterialIcons = [], typingMaterialIconColor, typingLabel, typingDescription } = typing
    if (!!flagShowTooltip === true)
      return (
        <KTooltip TransitionComponent={Zoom} arrow title={
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
          >
            <Grid item>
              <Stack direction="row" alignItems="center" gap={1}>
                {typingMaterialIcons && typingMaterialIcons.length > 0 && typingMaterialIcons.map(typingMaterialIcon => typingMaterialIcon && <UseDynamicIcon sx={dynamicIconSx} fontSize={fontSize} iconName={typingMaterialIcon} style={{ color: typingMaterialIconColor }} />)}
                {!!flagShowTooltipLabel === true && typingLabel}
              </Stack>
            </Grid>
            {!!flagShowTypingDescription === true && <Grid item>{typingDescription}</Grid>}
            {!!showUpdateBy === true && <Grid item>Mis à jour par :<br></br> {showUpdateBy}</Grid>}
            {!!showUpdateDatetime === true && !!dayjs(showUpdateDatetime).isValid() === true && <Grid item>Le {dayjs(showUpdateDatetime).format("DD/MM/YYYY")}</Grid>}
          </Grid>
        } placement={"right-start"}>
          <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            wrap="nowrap"
            spacing="2">
            {typingMaterialIcons && typingMaterialIcons.length > 0 && typingMaterialIcons.map(typingMaterialIcon =>
              <Grid item>{typingMaterialIcon && <UseDynamicIcon sx={dynamicIconSx} fontSize={fontSize} iconName={typingMaterialIcon} style={{ color: typingMaterialIconColor }} />}</Grid>
            )}
            <Grid item> {!!flagShowLabel === true && typingLabel}</Grid>
          </Grid>
        </KTooltip>
      )
    return (
      <Grid container direction="row" alignItems="center">

        {typingMaterialIcons && typingMaterialIcons.length > 0 && typingMaterialIcons.map(typingMaterialIcon =>
          <Grid item>{typingMaterialIcon && <UseDynamicIcon sx={dynamicIconSx} fontSize={fontSize} iconName={typingMaterialIcon} style={{ color: typingMaterialIconColor }} />}</Grid>
        )}
        <Grid item> {!!flagShowLabel === true && typingLabel}</Grid>
      </Grid>
    )
  }
  const initInfra = async () => {
    let endpoint = config.api.public.infra.react.url
    let responseKeepapi = await restIO({ endpoint, jwt: true })
    let { data:datas, code } = responseKeepapi
    if (code !== 'ok') {
      // return enqueueSnackbar(`Votre demande ne peux aboutir. 202202081038`, { variant: 'warning', })
    }
    if (datas && datas.length > 0) {
      datas[0].typings = datas[0].typings.map(typing => {
        let { typingMaterialIcons } = typing
        try {
          typing.typingMaterialIcons = JSON.parse(typingMaterialIcons)
        } catch (error) {
          typing.typingMaterialIcons = [typingMaterialIcons]
        }
        return typing
      })
    }
    if (datas.length > 0) {
      setInfraStore(datas[0])
      setInfraStoreRightPermissionDenied(false)
      return true
    }
    return false
  }
  return (
    <KMInfraContext.Provider value={{
      infraStore
      , infraStoreRightPermissionDenied
      , getTypingIdBy
      , getTypingBy
      , getTypingsBy
      , formatTyping
      , formatTypingShortIcon
    }}>
      {props.children}
    </KMInfraContext.Provider>
  )
}
export default KMInfraProvider

export const withInfraStore = Component => props => (
  <KMInfraContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMInfraContext.Consumer>
)
