// src/ui/theme/index.js
import {  purple } from '@material-ui/core/colors'

import { createTheme } from '@material-ui/core/styles';

const defaultTheme = createTheme();

const theme = createTheme({
  // typography: {
  //   fontSize: defaultTheme.typography.fontSize + 2
  // },
  palette: {
    primary: purple,
    text:{
      disabled:'#000'
    }
  },
  overrides: {
    MuiTooltip: {
      tooltip: {
        backgroundColor: 'white',
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: defaultTheme.shadows[1],
        fontSize: 11,
      },
    },
  },
});

export default theme;



