
import React, { lazy } from 'react'
import { CameraRounded, Dashboard, PersonRounded, PanToolRounded, InfoRounded, HomeWorkRounded, ScheduleRounded } from '@material-ui/icons'

// import ContentPaste from "@material-ui/icons/ContentPaste";
const InfoPage = lazy(() => import('views/Info/info'));

const DashboardPage = lazy(() => import('views/Dashboard/Dashboard'));
// import UserProfile from 'views/UserProfile/UserProfile.jsx'
const KMUserManagementView = lazy(() => import('views/KMUserManagementView/KMUserManagementView'));
const KMCareFolderView = lazy(() => import('views/KMCareFolderView/KMCareFolderView'));
const KMProfilDetailView = lazy(() => import('views/KMProfilDetailView/KMProfilDetailView'));
const KMCareFolderListView = lazy(() => import('views/KMCareFolderListView/KMCareFolderListView'));
const KMListEstablishmentView = lazy(() => import('views/KMListEstablishmentView/KMListEstablishmentView'));
const KMEstablishmentDetailView = lazy(() => import('views/KMEstablishmentDetailView/KMEstablishmentDetailView'));
const KMScheduledBroadcastListView = lazy(() => import('views/KMScheduledBroadcastListView/KMScheduledBroadcastListView'));
const KMScheduledBroadcastDetailView = lazy(() => import('views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView'));

const dashboardRoutes = [
  {
    path: '/dashboard',
    sidebarName: 'Tableau de bord',
    navbarName: <div><Dashboard />Tableau de bord</div>,
    icon: Dashboard,
    component: DashboardPage,
    rbac: 'views/Dashboard/Dashboard:visit',
    pathPattern: /\/dashboard/
  }, {
    path: '/careFolders',
    sidebarName: 'Dossier étudiant',
    navbarName: <div><CameraRounded />Liste des dossiers étudiant</div>,
    icon: CameraRounded,
    component: KMCareFolderListView,
    rbac: 'views/KMCareFolderListView/KMCareFolderListView:visit',
    pathPattern: /^\/careFolders$|^\/careFolder\/[0-9]+$/
  }, {
    path: '/careFolder/:careFolderId',
    sidebarName: '',
    navbarName: <div><CameraRounded />Dossier Etudiant</div>,
    icon: CameraRounded,
    component: KMCareFolderView,
    rbac: 'views/KMCareFolderView/KMCareFolderView:visit',
    pathPattern: /\/careFolder\/[0-9]+/
  }, {
    path: '/user',
    sidebarName: 'Droits',
    navbarName: <div><PanToolRounded />Droits</div>,
    icon: PanToolRounded,
    component: KMUserManagementView,
    rbac: 'views/KMUserManagementView/KMUserManagementView:visit',
    pathPattern: /^\/user$/
  }, {
    path: '/userProfil/:userId',
    sidebarName: 'Votre compte',
    navbarName: <div><PersonRounded />Votre compte</div>,
    icon: PersonRounded,
    component: KMProfilDetailView,
    rbac: 'views/UserProfile/UserProfile:visit',
    pathPattern: /\/userProfil\/[0-9]+/
  }, {
    path: '/infos',
    sidebarName: 'Infos',
    navbarName: <div><InfoRounded />Infos</div>,
    icon: InfoRounded,
    component: InfoPage,
    rbac: 'views/Infos/info:visit',
    pathPattern: /\/infos/
  },
  {
    path: '/establishments',
    sidebarName: 'Établissements',
    navbarName: <div><HomeWorkRounded />Liste des établissements</div>,
    icon: HomeWorkRounded,
    component: KMListEstablishmentView,
    rbac: 'views/KMListEstablishmentView/KMListEstablishmentView:visit',
    pathPattern: /^\/establishments$|^\/establishment\/[0-9]+$/

  }, {
    path: '/establishment/:establishmentId',
    sidebarName: '',
    navbarName: <div><HomeWorkRounded />Établissement</div>,
    icon: HomeWorkRounded,
    component: KMEstablishmentDetailView,
    rbac: 'views/KMEstablishmentDetailView/KMEstablishmentDetailView:visit',
    pathPattern: /\/establishment\/[0-9]+/
  },
  {
    path: '/scheduledBroadcasts',
    sidebarName: 'Messages planifiés',
    navbarName: <div><ScheduleRounded />Liste des messages planifiés</div>,
    icon: ScheduleRounded,
    component: KMScheduledBroadcastListView,
    rbac: 'views/KMScheduledBroadcastListView/KMScheduledBroadcastListView:visit',
    pathPattern: /^\/scheduledBroadcasts$|^\/scheduledBroadcast\/[0-9]+$/

  },
  {
    path: '/scheduledBroadcast/:scheduledBroadcastId',
    sidebarName: '',
    navbarName: <div><ScheduleRounded />Message planifié</div>,
    icon: ScheduleRounded,
    component: KMScheduledBroadcastDetailView,
    rbac: 'views/KMScheduledBroadcastDetailView/KMScheduledBroadcastDetailView:visit',
    pathPattern: /\/scheduledBroadcast\/[0-9]+/
  },
  { redirect: true, path: '/', to: '/dashboard', navbarName: 'Redirect' }
]

export default dashboardRoutes
