import config from 'config'
import { useEffect } from 'react'
import { deleteAllCookies } from 'utils/cookie'
import { removeServiceWorker } from 'utils/serviceWorker'
import { useLocation } from 'react-router-dom';
import { restIO } from 'utils/io';

const NotLogged = () => {
    let location = useLocation();
    useEffect(() => {
        let stopRedirect = window.localStorage.getItem('stopRedirect') || false
        if (!!stopRedirect === true) debugger
        removeServiceWorker()
        deleteAllCookies() // deleteAllCookies(["kmSession"])
        // deloguer le coté serveur (jwt blacklist, bdd, cookie etc)
        const logout = async () => {
            const oauthLogoutUrl = config.oauth.logout.url
            try { await restIO({ endpoint: oauthLogoutUrl, jwt: true }) } catch (error) { console.log(error) }
        }
        logout()
        window.sessionStorage.clear()
        window.localStorage.removeItem("JWT")
        window.localStorage.removeItem("redirectToReferrer")
        window.localStorage.removeItem("refreshLastAliveDatetime")
        window.localStorage.removeItem("refreshNotification")
        // nous stockons en session local sur le domaine la route demandé par l'utilisateur
        let tab = location.search.split("=")
        let pathname = '/dashboard'
        if (tab.length === 2)
            pathname = tab[1]
        let { from } = { from: { pathname } }
        window.localStorage.setItem('redirectToReferrer', JSON.stringify(from))
        // redirection vers la page de logout du front
        window.location.href = 'https://' + config.frontDomainName + '/logout'
    }, [])
    return null;
}
export default NotLogged
