
// type: 'light',
//         primary: {
//         main: '#0360b7',
//         },
//         secondary: {
//         main: '#fd4a7d',
//         },

/**
 * primary   : #bfa056
 * secondary : #1b1b1b

    gold: #bfa056
    plurelya : #32285c
    plurelya orange : #f0ab00
    plurelya corrected for both dark and light mode : #8d15a8
    keepapi orange : #f49e12

 */

const theme = {
    palette: {
        mode: 'light',
        type: 'light',
        primary: {
            main: '#F50057',
            light: '#eef4fe',
        },
        secondary: {
            main: '#9C27B0',
        },
        black: {
            main: '#000',
        },
        red: {
            main: '#cd3333',
        },
        green: {
            main: '#32cd32',
        },
        purple: {
            main: '#7c108c',
        },
        lightgrey: {
            main: '#757575',
        },
        coolgray: {
            main: '#1b1b1b',
        },
        background: {
            default: "#fafafa"
        },
        backgroundHover: {
            default: "#e9e9e9"
        },
    },
    // typography: {
    //     button: {
    //     textTransform: 'none'
    //     },
    menuZIndex: 1198,
    padding: 0,
    borderRadius: 20,
    overrides: {
        MuiSwitch: {
            root: {
                width: 42,
                height: 26,
                padding: 0,
                margin: 8,
            },
            switchBase: {
                padding: 1,
                '&$checked, &$colorPrimary$checked, &$colorSecondary$checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + $track': {
                    opacity: 1,
                    border: 'none',
                },
                },
            },
            thumb: {
                width: 24,
                height: 24,
            },
            track: {
                borderRadius: 13,
                border: '1px solid #bdbdbd',
                backgroundColor: '#f1f1f1',
                opacity: 1,
                transition: 'background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
            },
        },
    },
    components: {
        MuiListItemIcon: {
            styleOverrides: {
                root: {
                    color: '#1b1b1b'
                }
            }
        },
        MuiSlider: {
            styleOverrides: {
                root: {
                    marginLeft: "12px",
                    marginTop: "10px"
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    borderRadius: 20,
                    fontSize: "12px",
                    backgroundColor: "#757575",
                    color: "#fff"
                },
                arrow: {
                    color: "#757575"
                }
            }
        },
        // MuiButton: {
        //     styleOverrides: {
        //         root: {
        //             borderRadius: 20,
        //         }
        //     }
        // },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                }
            }
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                paper: {
                    borderRadius: "0 0 20px 20px",
                    boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px;"
                }
            }
        },
        // NestedNavMenu: {
        //     styleOverrides: {
        //         opacity: 0
        //     },
        //     nested: {
        //         paddingLeft: 4,
        //     },
        //     nestedSecondLevel: {
        //         paddingLeft: 8
        //     }
        // },
    }
}

export default theme;
