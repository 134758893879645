import KMCan from 'components/KMCan/KMCan'
import React, { Fragment, useEffect, useState } from "react";
import Avatar from '@material-ui/core/Avatar';
import withWidth from '@material-ui/core/withWidth'
import { withSnackbar } from 'notistack';
import classNames from "classnames";
import superagent from 'superagent'
import config from 'config'
// @material-ui/core components
// import List from '@material-ui/core/List';
// import ListItem from '@material-ui/core/ListItem';
// import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
// import ListItemText from '@material-ui/core/ListItemText';
// import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import withStyles from "@material-ui/core/styles/withStyles";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Hidden from "@material-ui/core/Hidden";
import Poppers from "@material-ui/core/Popper";
// @material-ui/icons
import PersonIcon from "@material-ui/icons/PersonRounded";
import { CameraRounded } from '@material-ui/icons'
import NotificationsIcon from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";
import Search from "@material-ui/icons/Search";
// core components
import CustomInput from "components/CustomInput/CustomInput.jsx";
import Button from "components/CustomButtons/Button.jsx";
import { Link } from 'react-router-dom'
import headerLinksStyle from "assets/jss/material-dashboard-react/components/headerLinksStyle.jsx";
import { withUser } from 'stores/KMUserProvider'

import moment from 'moment-timezone'
import 'moment/min/locales'
import useDebounce from "../../utils/debounce";
moment.locale('fr')
moment.tz.setDefault('Europe/Paris');


const HeaderLinks = (props) => {
  const [openNotification, setOpenNotification] = useState(false)
  const [loadNotification, setLoadNotification] = useState(false)
  const [openSearch, setOpenSearch] = useState(false)
  const [search, setSearch] = useState('')
  const [searchResults, setSearchResults] = useState([])
  const [notificationResults, setNotificationResults] = useState([])
  const [searchAnchorEl, setSearchAnchorEl] = useState(false)
  const [anchorEl, setAnchorEl] = useState(false)

  const { classes } = props;
  const handleToggle = () => {
    setOpenNotification(!openNotification)
  };
  const handleCloseOpenNotification = (event) => {
    setOpenNotification(false)
  };
  const handleCloseOpenSearch = (event) => {
    setOpenSearch(false)
  };
  const handleSearch = (event) => {
    let target = event.target
    setSearch(target.value)
    if (target.value.length >= 2) {
      try {
        setSearch(target.value)

      } catch (e) { console.log(e) }
    }
  }


  useEffect(() => {
    fetchNotification()
  }, [loadNotification])

  const fetchNotification = async () => {
    if (!!openNotification === false) {
      let apiResponseNetwork
      let apiUrl = config.api.notification.url
      try {
        apiResponseNetwork = await superagent
          .get(apiUrl)
          .set('X-JWT', window.localStorage.getItem('JWT'))
          .withCredentials()
          .timeout({
            response: 9000, // Wait 9 seconds for the server to start sending,
            deadline: 60000 // but allow 1 minute for the file to finish loading.
          })
      } catch (error) {
        // let message = `erreur réseau X120520191123 ${error}`
        // let variant = 'error'
        // props.enqueueSnackbar(message, { variant })
        return
      }
      let responseKeepapi = apiResponseNetwork.body
      if (responseKeepapi.code !== 'ok') {
      } else {
        setNotificationResults(responseKeepapi.data)
        setTimeout(() => {
          if(!!loadNotification) {
            handleToggle()
          }
        }, 100);

      }
    }
  }



  const fetchSearch = async () => {
    if (search === '') return
    if (search.length < 3) return
    let apiResponseNetwork
    let apiUrl = config.api.search.url
    try {
      apiResponseNetwork = await superagent
        .post(apiUrl)
        .send({ search: search })
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
        .timeout({
          response: 9000, // Wait 9 seconds for the server to start sending,
          deadline: 60000 // but allow 1 minute for the file to finish loading.
        })
    } catch (error) {
      let message = `erreur réseau X120520190916 ${error}`
      let variant = 'error'
      props.enqueueSnackbar(message, { variant })
      return
    }
    let responseKeepapi = apiResponseNetwork.body

    if (responseKeepapi.code !== 'ok') {
    } else {
      setSearchResults(responseKeepapi.data)
      setOpenSearch(true)
    }
  }
  const debouncedFetchSearch = useDebounce(search, 300);

  useEffect(() => {
    fetchSearch();
  }, [debouncedFetchSearch]);

  let myWidth = props.width === 'sm' || props.width === 'xs' ? 'smartphone' : 'large'
  return (
    <div>
      <div className={classes.searchWrapper}>
        <CustomInput
          formControlProps={{
            className: classes.margin + " " + classes.search
          }}
          inputProps={{
            placeholder: "Rechercher",
            inputProps: {
              "aria-label": "Search"
            },
            onChange: (event) => handleSearch(event),
            value: search,
            'aria-owns': openSearch ? "search-list-grow" : null,
            'aria-haspopup': "true"
          }}
        />
        {searchResults.length > 0 &&
          <Poppers
            open={openSearch}
            anchorEl={searchAnchorEl}
            transition
            disablePortal
            className={
              classNames({ [classes.popperClose]: !openSearch }) +
              " " +
              classes.pooperNav
            }
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                id="search-list-grow"
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom"
                }}
              >
                <Paper style={{position: "fixed",right: 0,top: '80px'}}>
                  <ClickAwayListener onClickAway={(e) => handleCloseOpenSearch(e)}>
                    <MenuList dense role="menu" >

                      {searchResults.map(searchResult => {
                        if (searchResult.type === "careFolder") {
                          return (
                            <Fragment>
                              <a href={"/careFolder/" + searchResult.careFolderId}>
                                <MenuItem
                                  onClick={(e) => handleCloseOpenNotification(e)}
                                  className={classes.dropdownItem}
                                >
                                  {searchResult.docPathEstablishment &&
                                    <img src={searchResult.docPathEstablishment} alt='' width={myWidth === 'smartphone' ? 30 : 50} />
                                  }
                                  <b> <CameraRounded />{searchResult.userLastName} {searchResult.userFirstName}</b>

                                  <br /> Rattaché à {searchResult.establishmentLabel}
                                </MenuItem>
                              </a>
                            </Fragment>

                          )
                        } else if (searchResult.type === "user") {
                          return (
                            <Fragment>
                              <a href={"/userProfil/" + searchResult.userId}>
                                <MenuItem
                                  onClick={(e) => handleCloseOpenNotification(e)}
                                  className={classes.dropdownItem}
                                >
                                  {searchResult.docPathEstablishment &&
                                    <img alt='' src={searchResult.docPathEstablishment} width={myWidth === 'smartphone' ? 30 : 50} />
                                  }
                                  <b>
                                    {searchResult.docPathAvatar ? (
                                      <Avatar alt={searchResult.userFirstName} src={searchResult.docPathAvatar} style={{ float: "right" }} />
                                    ) : (
                                      <PersonIcon />
                                    )
                                    }
                                    {searchResult.userFirstName} {searchResult.userLastName}</b>
                                  <br /> Tél : {searchResult.userMobilePhone}
                                  <br /> Mail {searchResult.userEmail}
                                </MenuItem>
                              </a>
                            </Fragment>

                          )
                        }

                      })
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Poppers>
        }

      </div>
      <KMCan
        perform='views/Dashboard/Dashboard:visit'
        yes={() => (
          <Link to={"/dashboard"}>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-label="Dashboard"
              className={classes.buttonLink}
            >
              <Dashboard className={classes.icons} />
              <Hidden mdUp implementation="css">
                <div className={classes.linkText}>Tableau de bord</div>
              </Hidden>
            </Button>
          </Link>
        )}
      />
      <KMCan
        perform={'views/notification'}
        yes={() => (
          <div className={classes.manager}>
            <Button
              color={window.innerWidth > 959 ? "transparent" : "white"}
              justIcon={window.innerWidth > 959}
              simple={!(window.innerWidth > 959)}
              aria-owns={openNotification ? "menu-list-grow" : null}
              aria-haspopup="true"
              onClick={() => setLoadNotification(!loadNotification)}
              className={classes.buttonLink}
            >
              <NotificationsIcon className={classes.icons} />
              {notificationResults.length > 0 &&
                <span className={classes.notifications}>{notificationResults.length}</span>
              }
              <Hidden mdUp implementation="css">
                <p className={classes.linkText}>
                  Notification
                </p>
              </Hidden>
            </Button>
            <Poppers
              open={!!openNotification}
              anchorEl={anchorEl}
              transition
              disablePortal
              className={
                classNames({ [classes.popperClose]: !openNotification }) +
                " " +
                classes.pooperNav
              }

            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom"
                  }}
                >
                  <Paper   style={{position: "fixed",right: 0,top: '80px'}}>
                    <ClickAwayListener onClickAway={(e) => handleCloseOpenNotification(e)}>
                      <MenuList role="menu">
                        {notificationResults.map(notificationResult => {
                          let careFolderStateClass = 'careFolderToTreat'
                          let careFolderStateInitial = "N"
                          let filterActif = true
                          if (notificationResult.careFolderState === 'NOUVELLE_DEMANDE_2' || notificationResult.careFolderState === 'NOUVELLE_DEMANDE_1') {
                            careFolderStateClass = 'careFolderToTreat'
                            careFolderStateInitial = "1"
                          } else if (notificationResult.careFolderState === 'PRECONISATION_TRANSMISE' || notificationResult.careFolderState === 'PRECONISATION_A_TRANSMETTRE_VERS_REFERENT_MAH_ETABLISSEMENT') {
                            careFolderStateClass = 'recommendationProcessed'
                            careFolderStateInitial = "2"
                          } else if (notificationResult.careFolderState === 'DECISION_TRANSMISE' || notificationResult.careFolderState === 'DECISION_A_TRANSMETTRE_A_ETUDIANT') {
                            careFolderStateClass = 'decisionProcessed'
                            careFolderStateInitial = "3"
                          }
                          let lastUpdate = moment().diff(moment(notificationResult.careFolderUpdateDatetime ? notificationResult.careFolderUpdateDatetime : notificationResult.careFolderCreateDatetime), 'day')

                          if (notificationResult.type === "careFolderToCheck") {
                            return (
                              <Link key={"careFolderToCheck_" + notificationResult.careFolderId} to={"/careFolder/" + notificationResult.careFolderId}>
                                {/* // <a key={"careFolderToCheck_" + notificationResult.careFolderId} href={"/careFolder/" + notificationResult.careFolderId}> */}
                                <MenuItem
                                  onClick={(e) => handleCloseOpenNotification(e)}
                                  className={classes.dropdownItem}
                                >
                                  {notificationResult.docPathEstablishment &&
                                    <img src={notificationResult.docPathEstablishment} alt='' width={myWidth === 'smartphone' ? 30 : 50} />
                                  }
                                  <ListItemIcon>

                                    <Avatar className={classes[careFolderStateClass]}> {careFolderStateInitial} </Avatar>
                                  </ListItemIcon>
                                  {notificationResult.userFirstName} {notificationResult.userLastName}
                                  Dernière modification : {lastUpdate === 0 ? `aujourd'hui` : `${lastUpdate} jour${lastUpdate > 1 ? 's' : ''}`}
                                </MenuItem>
                                {/* </a> */}
                              </Link>
                            )
                          }
                          return null
                        })
                        }
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
          </div>
        )}
      />
      {/* <Link to={"/userProfil/" + props.user.id}>
        <Button
          color={window.innerWidth > 959 ? "transparent" : "white"}
          justIcon={window.innerWidth > 959}
          simple={!(window.innerWidth > 959)}
          aria-label="PersonIcon"
          className={classes.buttonLink}
        >
          <PersonIcon className={classes.icons} />
          <Hidden mdUp implementation="css">
            <div className={classes.linkText}>Profile</div>
          </Hidden>
        </Button>
      </Link> */}
      {/* <App/> */}
    </div>
  );
}

export default withWidth()(withStyles(headerLinksStyle)(withSnackbar(withUser(HeaderLinks))))
