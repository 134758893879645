import React, { createContext, Component } from 'react'
import config from 'config'
// import _ from 'lodash'
import superagent from 'superagent'

const referenciels = {
  article: {
    type: 'complexArray',
    complexArrayChecks: [
      {
        type: 'boolean',
        field: 'recommendationFlagHasDetail',
        nullable: false,
        acceptEmptyValue: false,
        pattern: /true/,
        helperText: 'le champ prénom doit être renseigné'
      },
      {
        type: 'string',
        field: 'careFolderRecommendationFlagSelectedByDoctorCourse',
        nullable: false,
        acceptEmptyValue: false,
        pattern: /[0-9a-zA-Z]{1}/,
        helperText: 'Veuillez détailler ce champ'
      },
      {
        type: 'string',
        field: 'careFolderRecommendationFlagSelectedByDoctorExam',
        nullable: false,
        acceptEmptyValue: false,
        pattern: /[0-9a-zA-Z]{1}/,
        helperText: 'Veuillez détailler ce champ'
      }

    ]

  },
  userFirstName: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: /[0-9a-zA-Z]{1}/,
    helperText: 'le champ prénom doit être renseigné'
  },
  userLastName: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: /[0-9a-zA-Z]{1}/,
    helperText: 'le champ nom doit être renseigné'
  },
  userEmail: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i,
    helperText: 'le champ email doit être renseigné'
  },
  userEmailCatho: {
    type: 'string',
    nullable: true,
    acceptEmptyValue: true,
    pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,6}$/i,
    helperText: `le champ email doit être renseigné`
  },
  userMobilePhone: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: /\+{0,1}[0-9 ]{16,}|\+{0,1}[0-9]{11,}/,
    helperText: 'le champ numéro de mobile doit être renseigné'
  },

  userProductPackage7: {
    type: 'boolean',
    nullable: true,
    acceptEmptyValue: false,
    pattern: /true|false/,
    otherFieldsToCheck: ['userProductPackage7Detail'],
    helperText: ''
  },
  userProductPackage7Detail: {
    type: 'string',
    nullable: true,
    acceptEmptyValue: false,
    testOnlyIf: 'userProductPackage7',
    testOnlyPattern: /true/,
    pattern: /[0-9a-zA-Z]{1}/,
    helperText: 'Veuillez détailler ce champ'
  },
  userSchoolYear: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: null,
    otherFieldsToCheck: ['userSchoolYearOther'],
    helperText: ''
  },

  userSchoolYearOther: {
    type: 'string',
    nullable: true,
    acceptEmptyValue: false,
    testOnlyIf: 'userSchoolYear',
    testOnlyPattern: /Other/,
    pattern: /[0-9a-zA-Z]{1}/,
    helperText: 'Veuillez détailler ce champ'
  },
  userTrainingLabel: {
    type: 'string',
    nullable: false,
    acceptEmptyValue: false,
    pattern: /[0-9a-zA-Z]{1}/,
    helperText: 'le champ nom doit être renseigné'
  },
  

}
export const KMCareFolderContext = createContext({
  careFolderStore: {}, // store all the careFolder details
  careFolderErrorStore: {},
  updateCareFolderStore: () => { },
  updateCareFolderFlagUpdated: () => { },
  checkFieldCareFolderStore: () => { },
  checkAllFieldCareFolderStore: () => { },
  initCareFolderStore: () => { },
  reloadCareFolderStore: () => { },
  getCareFolderPackageByCode: () => { },
  getCareFolderArticleByCode: () => { },
  careFolderStoreRightPermissionDenied: true
})

export class KMCareFolderProvider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      careFolderStore: {}, // store all the careFolder details
      careFolderFlagUpdated: false, // true when careFolderStore change, false when saved
      careFolderErrorStore: {},
      updateCareFolderStore: this.updateCareFolderStore,
      updateCareFolderFlagUpdated: this.updateCareFolderFlagUpdated,
      checkFieldCareFolderStore: this.checkFieldCareFolderStore,
      checkAllFieldCareFolderStore: this.checkAllFieldCareFolderStore,
      initCareFolderStore: this.initCareFolderStore,
      reloadCareFolderStore: this.reloadCareFolderStore,
      careFolderStoreRightPermissionDenied: true,
      getCareFolderPackageByCode: this.getCareFolderPackageByCode,
      getCareFolderArticleByCode: this.getCareFolderArticleByCode

    }
  }
  getCareFolderPackageByCode = (code) => ('package' in this.state.careFolderStore) ? this.state.careFolderStore.package.find(p => p.recommendationCode === code) || {} : {}
  getCareFolderArticleByCode = (code) => ('article' in this.state.careFolderStore) ? this.state.careFolderStore.article.find(p => p.recommendationCode === code) || {} : {}

  reloadCareFolderStore = (newCareFolderId) => {
    this.setState({ careFolderStoreRightPermissionDenied: true, careFolderFlagUpdated: false }) // will force refresh :)
    this.initCareFolderStore(newCareFolderId || this.state.careFolderStore.careFolderId)
  }
  updateCareFolderStore = (key, value, mutationDataFlag = true) => {
    let myCareFolderStore = this.state.careFolderStore // faster deep copy 
    myCareFolderStore[key] = value
    let careFolderFlagUpdated = !!(this.state.careFolderFlagUpdated | mutationDataFlag)
    console.log(this.state.careFolderFlagUpdated )
    console.log(mutationDataFlag)
    if (careFolderFlagUpdated === true) {
      // alert('ici')
      // debugger
    }
    this.setState({ careFolderStore: myCareFolderStore, careFolderFlagUpdated: careFolderFlagUpdated }, this.checkFieldCareFolderStore(key, this.state.careFolderStore[key]))
  }
  updateCareFolderFlagUpdated = (bool) => {
    this.setState({ careFolderFlagUpdated: bool })
  }

  // all referenciel check
  checkAllFieldCareFolderStore = () => {
    for (let referenciel in referenciels) {
      this.checkFieldCareFolderStore(referenciel, this.state.careFolderStore[referenciel])
    }
  }
  // one field check
  checkFieldCareFolderStore = (key, value) => {
    let myCareFolderErrorStore = this.state.careFolderErrorStore // faster deep copy 
    let noError = true

    if (key in referenciels) {

      let { type, pattern, otherFieldsToCheck, testOnlyIf, testOnlyPattern, nullable, acceptEmptyValue } = referenciels[key]
      if (value && type === 'complexArray') {
        let myValue = value.filter((recommendation, index) => {
          // if (recommendation.recommendationId == 4) {
          //debugger
          // }
          if (!!recommendation.recommendationFlagHasDetail === true) {
            if (!!recommendation.careFolderRecommendationFlagSelectedByDoctorCourse === true || !!recommendation.careFolderRecommendationFlagSelectedByDoctorExam === true) {
              if (!!recommendation.careFolderRecommendationFlagSelectedByDoctorExam === true && recommendation.careFolderRecommendationSelectedByDoctorExamDetail === null) {
                return true
              }
              else if (!!recommendation.careFolderRecommendationFlagSelectedByDoctorCourse === true && recommendation.careFolderRecommendationSelectedByDoctorCourseDetail === null) {
                return true
              }
              else if (!!recommendation.careFolderRecommendationFlagSelectedByDoctorExam === true && recommendation.careFolderRecommendationSelectedByDoctorExamDetail.length <= 0) {
                return true
              } else if (!!recommendation.careFolderRecommendationFlagSelectedByDoctorCourse === true && recommendation.careFolderRecommendationSelectedByDoctorCourseDetail.length <= 0) {
                return true
              }
            }
          }
          return false
        })
        if (myValue.length > 0)
          myCareFolderErrorStore[key] = myValue
        else
          delete myCareFolderErrorStore[key]
      } else {
        let makeSomeMatch = true
        if (value === null && nullable === false) {
          noError = false
          makeSomeMatch = false
        } else if (value === '' && acceptEmptyValue === true) {
          makeSomeMatch = false
        }

        if (type === 'boolean') value = !!value + '' // cast to "false" or "true" string type for convenience
        if (testOnlyIf && testOnlyPattern) {
          let testOnlyIfValue = this.state.careFolderStore[testOnlyIf]
          let testOnlyIfType = referenciels[testOnlyIf].type
          if (testOnlyIfType === 'boolean') testOnlyIfValue = !!testOnlyIfValue + ''
          if (testOnlyIfValue && !testOnlyIfValue.match(testOnlyPattern)) {
            noError = true
            makeSomeMatch = false
          } else if ((value === null || value === '') && acceptEmptyValue === false) {
            noError = false
            makeSomeMatch = false
          }

        }
        if (noError === true && makeSomeMatch === true && value !== null && pattern !== null && !value.match(pattern)) {
          noError = false
        }
        // check other fields if referenciel says so
        if (otherFieldsToCheck) {
          otherFieldsToCheck.map((otherFieldToCheck) => {
            this.checkFieldCareFolderStore(otherFieldToCheck, this.state.careFolderStore[otherFieldToCheck])
          })
        }

        if (noError === true) delete myCareFolderErrorStore[key]
        else {
          myCareFolderErrorStore[key] = referenciels[key]

        }
      }
    }

    this.setState({ careFolderErrorStore: myCareFolderErrorStore })
  }
  initCareFolderStore = async (careFolderId) => {
    let apiUrl = config.api.careFolderDetail.url
    apiUrl = apiUrl.replace('___CAREFOLDERID___', careFolderId)
    let apiResponseNetwork
    try {
      apiResponseNetwork = await superagent
        .get(apiUrl)
        .set('Content-Type', 'application/json')
        .set('Accept', 'application/json')
        .set('X-JWT', window.localStorage.getItem('JWT'))
        .withCredentials()
      // .send({})
    } catch (error) { // network error && 401 not authenticated
      return
    }

    let responseKeepapi = apiResponseNetwork.body
    if (responseKeepapi.data.length > 0) {

      let careFolderStore = responseKeepapi.data[0]
      this.setState({ careFolderStore, careFolderStoreRightPermissionDenied: false, careFolderFlagUpdated: false })
    }
  }

  render() {
    return (
      <KMCareFolderContext.Provider value={this.state}>
        {this.props.children}
      </KMCareFolderContext.Provider>
    )
  }
}

export const withCareFolderStore = Component => props => (
  <KMCareFolderContext.Consumer>
    {store => {
      return (<Component {...props} {...store} />)
    }
    }
  </KMCareFolderContext.Consumer>
)

/** * HOW TO USE IT
import React, { Component } from "react";
import { withCareFolder } from 'stores/KMCareFolderProvider'

class TOTO extends Component {
  render () {
    return (<h1>Hello {this.props.careFolder.firstName}!</h1>)
  }
}
export default withCareFolder(TOTO)

 */
