const removeCookieByDomain = (name, path, domain) => document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;${path ? " path=" + path + ";" : ""}${domain ? " domain=" + domain + ";" : ""}`
const deleteAllCookies = (restrictByNames = []) => {
    let cookies = document.cookie.split(";");
    for (let i = 0; i < cookies.length; i++) {
        let cookie = cookies[i];
        let eqPos = cookie.indexOf("=");
        let name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        if (restrictByNames.length > 0 && !restrictByNames.includes(name)) continue; // do not suppress all cookies only those contained in restrictByNames
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
}
const getCookie = (name) => {
    if (!document.cookie) return null

    const myCookie = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='));

    if (myCookie.length === 0) return null
    return decodeURIComponent(myCookie[0].split('=')[1]);
}

export { removeCookieByDomain, deleteAllCookies, getCookie }