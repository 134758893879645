const removeServiceWorker = ()=>{
    try {
        caches.keys().then(function (names) {
          for (let name of names) {
            caches.delete(name)
            console.log('caches.delete', name)
          }
        })
        navigator.serviceWorker.getRegistrations()
          .then(registrations => {
            for (let registration of registrations) {
              registration.unregister().then((flag) => console.log('unregister', flag, registration))
            }
          })
      } catch (error) { console.log("try to delete cache failed") }
}
export {removeServiceWorker}