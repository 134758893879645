import * as MuiIcons from '@mui/icons-material'

const useDynamicIcon = (iconName) => {
  const Icon = MuiIcons[iconName]
  return Icon
}
const UseDynamicIcon = (props) => {
  let { iconName, ...rest } = props
  const Icon = useDynamicIcon(iconName)
  return <Icon {...rest} />
}

export default UseDynamicIcon
export { useDynamicIcon }