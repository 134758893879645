// require(`dotenv`).config()
module.exports = {
  version: process.env.REACT_APP_VERSION,
  ribbonMessage: process.env.REACT_APP_RIBBON_MESSAGE,
  SOCKET_URL: process.env.SOCKET_URL,
  mainDomainName: process.env.REACT_APP_DOMAIN_NAME,
  frontDomainName: process.env.REACT_APP_FRONT_DOMAIN_NAME,
  socialNetwork: {
    facebook: {
      appId: process.env.REACT_APP_FACEBOOK_APPID || "777934109627485"
    },
    google: {
      clientId: process.env.REACT_APP_GOOGLE_CLIENTID || "1007524147148-r7515baluupsi7n8d2531pju99n0ehmg.apps.googleusercontent.com"
    },
    linkedIn: {
      clientId: process.env.REACT_APP_LINKEDIN_CLIENTID || ""
    },
    microsoft: {
      clientId: process.env.REACT_APP_MICROSOFT_CLIENTID || "5aaf0a0e-56a0-40f4-9d86-d2d057b41898"
    }

  },
  admin: {
    publicUrl: process.env.REACT_APP_ADMIN_URL_PUBLIC,

  },
  app: {
    debugMode: process.env.REACT_APP_DEBUG_MODE || !!window.localStorage.getItem('debugMode') === true || false,
  },
  dashboard: {
    dashboardTodayEvent: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardTodayEvent`,
    },
    dashboardNextEvent: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardNextEvent`,
    },
    dashboardNextEventToTreat: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/dashboardNextEventToTreat`,
    },
    userAwaitingForAccreditation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/dashboard/userAwaitingForAccreditation`,
    },

  },
  setting: {
    getSetting: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/setting/getSetting`,
    }
  },
  stats: {
    careFolderYearlyValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderYearlyValidate`,
    },
    careFolderByEstablishment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderByEstablishment`,
    },
    careFolderRatioLastWeek: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderRatioLastWeek`,
    },
    mailStatsMonthly: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/mailStatsMonthly`,
    },
    // ! mah stats : new carefoler count
    getNewCareFolderCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/newCareFolderCount`,
    },
    // ! mah stats : weekly
    getWeeklyNewCareFolderCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/weeklyNewCareFolderCount`,
    },
    // ! mah stats count carefolder being analyzed
    getCareFolderBeingAnalyzedCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderBeingAnalyzedCount`,
    },
    // ! mah stats count carefolder transmitted decision
    getCareFolderTransmittedDecisionCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderTransmittedDecisionCount`,
    },
    getCareFolderTemporary: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderTemporary`,
    },
    // ! mah stats count all students involved
    getCareFolderStudentsInvolvedCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderStudentsInvolvedCount`,
    },
    // ! mah stats dashboard db weight
    stockageStatsWeight: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/stockageStatsWeight`,
    },
    careFolderYearlyCount: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderYearlyCount`,
    },


    eventStatsNbValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsNbValidate`,
    },
    eventStatsNbPending: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsNbPending`,
    },
    eventStatsPendingBeginInLessThanXDays: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsPendingBeginInLessThanXDays`,
    },
    userStatsNbActif: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsNbActif`,
    },
    userStatsNbAll: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsNbAll`,
    },
    careFolderDailyCreated: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/careFolderDailyCreated`,
    },
    eventStatsDailyValidateLastWeek: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsDailyValidateLastWeek`,
    },
    eventStatsYearlyValidate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/eventStatsYearlyValidate`,
    },
    userStatsYearlyActifAndNoActif: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/stats/userStatsYearlyActifAndNoActif`,
    },






  },
  webhook: {
    addEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/add`,
    },
    archiveEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/archive`,
    },
    desarchiveEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/desarchive`,
    },
    updateEstablishment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/establishment/update`,
    },
    addBlankCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/addBlank`,
    },
    archiveCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/archive`,
    },
    updateCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/update`,
    },
    duplicateCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/duplicate`,
    },
    reOpenCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/reOpen`,
    },
    renewCareFolder: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/careFolder/renew`,
    },
    updateEventFlagPublishInWeb: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/updateEventFlagPublishInWeb`,
    },
    anonymizeUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/anonymize`,
    },
    forceResetPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/forceResetPassword`,
    },

    deleteDoc: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/delete`,
    },
    softDeleteDoc: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/softDeleteDoc`,
    },
    updateDoc: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/updateDoc`,
    },
    activateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/activateUser`,
    },
    desactivateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/desactivateUser`,
    },
    archiveEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/archive`,
    },
    cancelEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/cancel`,
    },
    duplicateEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/duplicate`,
    },
    expirePasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/expirePassword`,
    },
    forgotPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/forgotPassword`,
    },
    resetPasswordUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/resetPassword`,
    },
    updateEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/update`,
    },
    updateUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/update`,
    },
    updateSocialNetworkConnect: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/updateSocialNetworkConnect`,
    },
    deactivateSocialNetworkConnect: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/deactivateSocialNetworkConnect`,
    },
    uploadImg: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/doc/upload`,
    },
    addEvent: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/add`,
    },
    addUser: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/user/add`,
    },
    updateEventValidationLevel: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/event/update/validationLevel`,
    },
    addAssociation: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/association/add`,
    },
    addUserComment: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/userComment/add`,
    },
    addScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/add`,
    },
    activateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/activate`,
    },
    desactivateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/desactivate`,
    },
    updateScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/update`,
    },
    archiveScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/archive`,
    },
    unArchiveScheduledBroadcast: {
      url: `https://wh.${process.env.REACT_APP_DOMAIN_NAME}/scheduledBroadcast/unArchive`,
    },
  },
  api: {
    public: {
      infra: {
        react: {
          url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/api/infra/react`,
        },
      },
      reactTour: {
          url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/api/reactTour/reactTour`,
      },
    },
    filter: {
      query: {
        url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/api/filter/query`,
      },
    },
    report: {
      query: {
        url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/api/report/query`,
      },
    },
    common: {
      filter: {
        url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v2/common/filters/___filterId___`
      },

    },
    establishmentDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/establishment/___ESTABLISHMENTID___/detail`
    },
    associationDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/association/___ASSOCIATIONID___/detail`
    },
    area: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/area`
    },
    client: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/session/client`
    },
    establishment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/establishment`
    },
    etablissement: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/etablissement`
    },
    event: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event`
    },
    eventDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event/___EVENTID___/detail`
    },
    eventAssociation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/event/___EVENTID___/association`
    },
    acl: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/kmAcl`
    },
    association: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/association`
    },
    location: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/location`
    },
    notification: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/notification`
    },
    product: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/product`
    },
    productLocation: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/productLocation`
    },
    productStockByLocationAndDate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/product/stockByLocationAndDate`
    },
    profilDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/profil/___PROFILID___/detail`
    },
    scheduledBroadcast: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/scheduledBroadcast`
    },
    scheduledBroadcastDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/scheduledBroadcast/___SCHEDULEDBROADCASTID___/detail`
    },
    search: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/search`
    },
    segment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/segment`
    },

    student: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/student`
    },


    userSession: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/session/user`
    },
    userRightManagement: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/userRightManagement`
    },
    userActivate: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/user`
    },
    careFolder: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/careFolder`
    },
    careFolderDetail: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/careFolder/___CAREFOLDERID___/detail`
    },
    pdfCareFolder: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/pdf/careFolder/___CAREFOLDERID___`
    },

    eventType: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/admin/eventType`
    }
  },
  navigation: {
    front: {
      url: `https://mah.univ-catholille.app`
    }
  },
  front: {
    establishment: {
      url: `https://api.${process.env.REACT_APP_DOMAIN_NAME}/v1/front/establishment`
    }
  },
  oauth: {
    logout: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/logout`
    },
    login: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/login`
    },
    register: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/register`
    },
    isAuthenticated: {
      url: `https://oauth.${process.env.REACT_APP_DOMAIN_NAME}/isAuthenticated`
    }
  },
  rgpdSentence: `
  Les informations fournies lors de la création de votre compte sont enregistrées dans une base informatisée par l'Institut Catholique de Lille à des fins de gestion administrative.

Elles sont conservées tant que votre compte demeure actif.

La base légale du traitement est l’intérêt légitime, il est conforme au règlement européen (UE) 2016/679 du 27 avril 2016 relatif à la protection des personnes physiques à l’égard du traitement des données à caractère personnel, et à la loi « Informatique, Fichiers et Libertés » n°78-17 du 6 janvier 1978 modifiée.

Vous pouvez accéder à votre profil et modifier ou supprimer vos données en allant sur le site univ.events puis en cliquant sur l’onglet « votre compte » et le bouton « désactiver mon compte ».
Pour exercer votre droit d'accès aux données vous concernant contactez : <a href="mailTo:dmg@univ-catholille.fr" style="color: #b0a38f; text-decoration: underline;">dmg@univ-catholille.fr</a>

Pour toute question sur le traitement de vos données dans ce dispositif, vous pouvez contacter notre délégué à la protection des données (DPO) : <a href="mailTo:dpo@univ-catholille.fr" style="color: #b0a38f; text-decoration: underline;">dpo@univ-catholille.fr</a>
<br />
<br /> 
Si vous estimez, après nous avoir contactés, que vos droits Informatique et Libertés ne sont pas respectés, vous pouvez adresser une réclamation en ligne à la CNIL : www.cnil.fr ou par courrier postal ; la CNIL, 3 Place de Fontenoy – TSA 80715 – 75334 PARIS CEDEX 07.

  `,
  brand: `${process.env.REACT_APP_FRONT_DOMAIN_NAME}`
}
