
import { SnackbarProvider } from 'notistack';
import Dashboard from 'layouts/Dashboard/Dashboard.js'
import ReactDOM from 'react-dom/client';
import React from 'react'
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom'
import 'assets/css/material-dashboard-react.css?v=1.5.0'
import 'assets/scss/material-kit-react.css?v=1.3.0'
import KMInfraProvider from 'stores/KMInfraProvider';
import Autolog from 'views/AutoLog';
import NotLogged from 'views/NotLogged';
import PrivateRouteLegacy from 'oauth/PrivateRouteLegacy'
import PrivateRoute from 'oauth/PrivateRoute'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <SnackbarProvider maxSnack={3}>
    <KMInfraProvider>
      <BrowserRouter >
        <Routes>
          <Route path="/autolog/:token" Component={Autolog} />
          <Route path="/login" Component={NotLogged} />
          <Route path="/logout" Component={NotLogged} />
        </Routes>
        <PrivateRoute />
      </BrowserRouter>
    </KMInfraProvider>
  </SnackbarProvider>
);
// ReactDOM.render(
//   <>coucou</>
//   // <SnackbarProvider maxSnack={3}>
//   //   <BrowserRouter history={hist}>
//   //     <Switch>
//   //       <Route path="/autolog/:token" component={Autolog} />
//   //       <Route path="/login" component={NotLogged} />
//   //       <Route path="/logout" component={NotLogged} />
//   //       <PrivateRouteLegacy path="/" component={Dashboard} />
//   //     </Switch>
//   //   </BrowserRouter>
//   // </SnackbarProvider>
//   ,
//   document.getElementById('root')
// )
